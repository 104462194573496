import React from 'react';

import {
  Form,
  FormGroup,
  Button,
  RadioButtonGroup,
  RadioButton,
  UnorderedList,
  ListItem,
  Accordion,
  AccordionItem,
} from 'carbon-components-react';

import { Content } from 'carbon-components-react/lib/components/UIShell';

export class InstallAllPrerequisite extends React.Component {
  proceedButton = () => {
    this.props.proceedButtonInstallAllPrerequisite();
  };

  goBackButton = () => {
    this.props.backButtonInstallAllPrerequisite();
  };

  render() {
    //const location = this.props.location;
    let version = '"Please select a version in step 1"';

    if (this.props.selectMigrationTarget.version) {
      version = this.props.selectMigrationTarget.version;
    }

    console.log('value selected: ', this.props.currentState.yesOrNo);

    return (
      <Content id="main-content">
        <div className="bx--grid">
          <div className="bx--row">
            <section className="bx--col-lg-10">
              <h2
                style={{
                  //fontWeight: '800',
                  margin: '20px 0',
                  fontSize: '24px',
                }}>
                6. Install all prerequisite service updates
              </h2>
              <p style={{ lineHeight: '20px' }}>
                Service updates are required for other products to compile
                programs with Enterprise COBOL 5/6 and also to bind, run and
                debug those programs. Now, with Enterprise COBOL 5/6, you can
                use the FIXCAT feature of SMP/E 3.5 or later to find the required services, which 
                are identified with a FIXCAT category name in HOLDDATA: </p>
                <div style={{ marginTop: '20px' }}>
                  <Accordion align='start'>
                  <AccordionItem title="Category names">
                  <p>-For COBOL 5.1: IBM.TargetSystem-RequiredService.Enterprise-COBOL.V5R1</p>
                  <p>-For COBOL 5.2: IBM.TargetSystem-RequiredService.Enterprise-COBOL.V5R2</p>
                  <p>-For COBOL 6.1: IBM.TargetSystem-RequiredService.Enterprise-COBOL.V6R1</p>
                  <p>-For COBOL 6.2: IBM.TargetSystem-RequiredService.Enterprise-COBOL.V6R2</p>
                  <p>-For COBOL 6.3: IBM.TargetSystem-RequiredService.Enterprise-COBOL.V6R3</p>
				  <p>-For COBOL 6.4: IBM.TargetSystem-RequiredService.Enterprise-COBOL.V6R4</p>
{/*                     <UnorderedList nested={false}>
              <ListItem>
                        For COBOL 5.1: IBM.TargetSystem-RequiredService.Enterprise-COBOL.V5R1
</ListItem>
                        <ListItem>
                        For COBOL 5.2: IBM.TargetSystem-RequiredService.Enterprise-COBOL.V5R2
</ListItem>
                        <ListItem>
                        For COBOL 6.1: IBM.TargetSystem-RequiredService.Enterprise-COBOL.V6R1
</ListItem>
                        <ListItem>
                        For COBOL 6.2: IBM.TargetSystem-RequiredService.Enterprise-COBOL.V6R2
</ListItem>
                        <ListItem>
                        For COBOL 6.3: IBM.TargetSystem-RequiredService.Enterprise-COBOL.V6R3
</ListItem>
                      </UnorderedList> */}
                  </AccordionItem>
                </Accordion>
                </div>  
              

              <p style={{ lineHeight: '23px', marginTop: '20px' }}>
              A HOLDDATA type FIXCAT (fix category) is used to associate an APAR to a particular category 
              of fix for necessary target system PTFs. To help identify PTFs required but not yet installed 
              for your upgrade to Enterprise COBOL 5/6 on your current system, use the SMP/E REPORT MISSINGFIX command. 
              Here is a sample command used to run against your z/OS CSI for COBOL 6.4 (and earlier versions):
              </p>
              <div style={{ marginTop: '20px' }}>
              <Accordion align='start'>
              <AccordionItem title="Sample command">
              <p>SET BDY(GLOBAL).<br/>
REPORT MISSINGFIX ZONES(ZOS13T)<br/>
FIXCAT(IBM.TargetSystem-RequiredService.Enterprise-COBOL.V5R1,<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;IBM.TargetSystem-RequiredService.Enterprise-COBOL.V5R2,<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;IBM.TargetSystem-RequiredService.Enterprise-COBOL.V6R1,<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;IBM.TargetSystem-RequiredService.Enterprise-COBOL.V6R2,<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;IBM.TargetSystem-RequiredService.Enterprise-COBOL.V6R3,<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;IBM.TargetSystem-RequiredService.Enterprise-COBOL.V6R4)
</p>
                  </AccordionItem>
                </Accordion>
                </div>

              <p style={{ lineHeight: '20px', marginTop: '20px' }}>
                Verify all prerequisite service updates (program temporary fixes
                    (PTFs)) 
                {!version.includes('5.2') &&
                  !version.includes('6.1') &&
                  !version.includes('6.2') &&
                  !version.includes('6.3') && 
				  !version.includes('6.4') && (
                    <p>"Please select a version in step 1"</p>
                  )}{' '}
                for {version} are installed.
              </p>

              <p style={{ lineHeight: '20px', marginTop: '20px' }}>
                Have you installed all the PTFs?
              </p>

              <div>
                <Form
                  className="some-class"
                  onSubmit={function noRefCheck() {}}>
                  <div style={{ marginTop: '20px' }}>
                    <FormGroup
                      className="some-class"
                      invalid={false}
                      //legendText="Radio Button heading"
                      message={false}
                      messageText="">
                      <RadioButtonGroup
                        labelPosition="right"
                        name="radio-button-group"
                        onChange={val => {
                          this.props.InstallAllPrerequisiteButton(val);
                        }}
                        valueSelected={this.props.currentState.yesOrNo}
                        orientation="horizontal">
                        <RadioButton
                          className="some-class"
                          id="radio-1"
                          labelText="No"
                          value="No"
                        />
                        <RadioButton
                          className="some-class"
                          id="radio-2"
                          labelText="Yes"
                          value="Yes"
                        />
                      </RadioButtonGroup>
                    </FormGroup>
                  </div>

                  <div>
                    {this.props.currentState.yesOrNo === 'No' && (
                      <div>
                        <p style={{ lineHeight: '20px' }}>
                          Install the prerequisite PTFs for all non-COBOL
                          products with pre-req COBOL PTFs on all systems where{' '}
                          {version} programs might run, including production
                          systems.
                        </p>

                        <p style={{ lineHeight: '20px', marginTop: '20px' }}>
                          Enterprise COBOL 5 and later might require service
                          updates (PTFs) for:
                        </p>

                        <UnorderedList>
                          <ListItem>CICS</ListItem>
                          <ListItem>Db2</ListItem>
                          <ListItem>Debug Tool</ListItem>
                          <ListItem>Fault Analyzer</ListItem>
                          <ListItem>z/OS</ListItem>
                        </UnorderedList>
                        
                        <p style={{ lineHeight: '20px', marginTop: '20px' }}>
                        For more information, see {' '}
                {version.includes('5.2') && (
                  <a
                  href="https://www.ibm.com/support/knowledgecenter/SS6SG3_5.2.0/com.ibm.cobol52.ent.doc/migrate/mg5apar.html"
                  rel="noopener noreferrer"
                  target="_blank">
                  Prerequisite software and service
                </a>
                )}
                {version.includes('6.1') && (
                  <a
                  href="https://www.ibm.com/support/knowledgecenter/SS6SG3_6.1.0/migrate/mg5apar.html"
                  rel="noopener noreferrer"
                  target="_blank">
                 Prerequisite software and service
                </a>
                )}
                {version.includes('6.2') && (
                  <a
                  href="https://www.ibm.com/support/knowledgecenter/SS6SG3_6.2.0/migrate/mg5apar.html"
                  rel="noopener noreferrer"
                  target="_blank">
                  Prerequisite software and service
                </a>
                )}
                {version.includes('6.3') && (
                                        <a
                                        href="https://www.ibm.com/support/knowledgecenter/SS6SG3_6.3.0/migrate/mg5apar.html"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                       Prerequisite software and service
                                      </a>
                )}
				{version.includes('6.4') && (
                                        <a
                                        href="https://www.ibm.com/support/knowledgecenter/SS6SG3_6.4.0/migrate/mg5apar.html"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                       Prerequisite software and service
                                      </a>
                )}.
                        </p>
                      </div>
                    )}
                  </div>

                  <div
                    style={{
                      alignItems: 'center',
                      display: 'flex',
                      flexWrap: 'wrap',
                      marginTop: '20px',
                      float: 'right',
                      paddingBottom: '48px',
                    }}>
                    <Button
                      className="some-class"
                      disabled={false}
                      kind="secondary"
                      tabIndex={0}
                      type="submit"
                      onClick={this.goBackButton}>
                      Go back
                    </Button>

                    <Button
                      style={{ marginLeft: '10px' }}
                      className="some-class"
                      disabled={false}
                      kind="primary"
                      tabIndex={0}
                      type="submit"
                      onClick={this.proceedButton}>
                      Proceed
                    </Button>
                  </div>
                </Form>
              </div>
            </section>
          </div>
        </div>
      </Content>
    );
  }
}
