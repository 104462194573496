//constants
export const FETCH_DATASETS = 'FETCH_DATASETS';
export const FETCH_DATASETS_BY_ID = 'FETCH_DATASETS_BY_ID';
export const FETCH_DATASETS_BY_ID_INIT = 'FETCH_DATASETS_BY_ID_INIT';
export const FETCH_DATASETS_BY_ID_SUCCESS = 'FETCH_DATASETS_BY_ID_SUCCESS';
export const FETCH_DATASETS_SUCCESS = 'FETCH_DATASETS_SUCCESS';

export const FETCH_DATASETS_INIT = 'FETCH_DATASETS_INIT';
export const FETCH_DATASETS_FAIL = 'FETCH_DATASETS_FAIL';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT = 'LOGOUT';

export const REJECT_AGREEMENT = 'REJECT_AGREEMENT';
export const ACCEPT_AGREEMENT = 'ACCEPT_AGREEMENT';
export const FETCH_USER_AGREEMENT_SUCCESS = 'FETCH_USER_AGREEMENT_SUCCESS';
