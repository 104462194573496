import React from 'react';

import {
  Form,
  FormGroup,
  Button,
  RadioButtonGroup,
  RadioButton,
} from 'carbon-components-react';

import { Content } from 'carbon-components-react/lib/components/UIShell';

export class VerifyRegionSize extends React.Component {
  proceedButton = () => {
    this.props.proceedButtonVerifyRegionSize();
  };

  goBackButton = () => {
    this.props.backButtonVerifyRegionSize();
  };

  render() {
    let version = '';

    if (this.props.selectMigrationTarget.version) {
      version = this.props.selectMigrationTarget.version;
    }

    //const location = this.props.location;
    return (
      <Content id="main-content">
        <div className="bx--grid">
          <div className="bx--row">
            <section className="bx--col-lg-10">
              <h2
                style={{
                  //fontWeight: '800',
                  margin: '20px 0',
                  fontSize: '24px',
                }}>
                7. Verify region sizes
              </h2>
              <p style={{ lineHeight: '20px' }}>
                Enterprise COBOL for z/OS 5 and 6 require more memory to run
                than previous COBOL compilers. TSO user IDs require 200 MB or
                more of memory.
              </p>

              <p style={{ lineHeight: '20px', marginTop: '20px' }}>
                In many environments, the maximum TSO user region size has to be raised to 200 MB or more.  
                If users cannot get a large enough region size to compile their programs, they
                will get a compiler ABORT and will not be able to compile any
                programs.
              </p>

              {version.includes('6') && (
                <p style={{ lineHeight: '20px', marginTop: '20px' }}>
                  For COBOL 6, the compiler always uses storage above the 2 GB
                  BAR. This means that if your system MEMLIMIT is set to zero,
                  the compiler will fail. Be sure to update your system MEMLIMIT
                  if it is currently set to zero. The z/OS default is 2 GB, that
                  is a good starting point. For very large programs, MEMLIMIT
                  might need to be set to 3 GB or 4 GB or more.
                </p>
              )}

              <p style={{ lineHeight: '20px', marginTop: '20px' }}>
                Have you ensured that your application programmers have adequate
                region sizes available for their TSO user IDs?
              </p>

              <div>
                <Form
                  className="some-class"
                  onSubmit={function noRefCheck() {}}>
                  <div style={{ marginTop: '20px' }}>
                    <FormGroup
                      className="some-class"
                      invalid={false}
                      //legendText="Radio Button heading"
                      message={false}
                      messageText="">
                      <RadioButtonGroup
                        labelPosition="right"
                        name="radio-button-group"
                        onChange={value => {
                          this.props.VerifyRegionSizeButton(value);
                        }}
                        valueSelected={this.props.currentState.yesOrNo}
                        orientation="horizontal">
                        <RadioButton
                          className="some-class"
                          id="radio-1"
                          labelText="No"
                          value="No"
                        />
                        <RadioButton
                          className="some-class"
                          id="radio-2"
                          labelText="Yes"
                          value="Yes"
                        />
                      </RadioButtonGroup>
                    </FormGroup>
                  </div>

                  <div
                    style={{
                      alignItems: 'center',
                      display: 'flex',
                      flexWrap: 'wrap',
                      marginTop: '20px',
                      float: 'right',
                      paddingBottom: '48px',
                    }}>
                    <Button
                      className="some-class"
                      disabled={false}
                      kind="secondary"
                      tabIndex={0}
                      type="submit"
                      onClick={this.goBackButton}>
                      Go back
                    </Button>

                    <Button
                      style={{ marginLeft: '10px' }}
                      className="some-class"
                      disabled={false}
                      kind="primary"
                      tabIndex={0}
                      type="submit"
                      onClick={this.proceedButton}>
                      Proceed
                    </Button>
                  </div>
                </Form>
              </div>
            </section>
          </div>
        </div>
      </Content>
    );
  }
}
