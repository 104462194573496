import React from 'react';

import {
  Form,
  FormGroup,
  Button,
  RadioButtonGroup,
  RadioButton,
  Modal,
  InlineNotification,
  NotificationActionButton,
  UnorderedList,
  ListItem,
  Accordion,
  AccordionItem,
} from 'carbon-components-react';

import { Content } from 'carbon-components-react/lib/components/UIShell';

export class RegressionTesting extends React.Component {
  proceedButton = () => {
    this.props.proceedButtonRegressionTesting();
  };

  goBackButton = () => {
    this.props.backButtonRegressionTesting();
  };

  closeModal = () => {
    this.props.closeModal();
  };

  openModal = () => {
    this.props.openModal();
  };

  render() {
    //const location = this.props.location;

    //console.log('this yes or no value is:', this.props.currentState.yesOrNo2);

    let openModalFlag = this.props.currentState.modealOpen;
    let regModalClass = "regtest_modal_" + this.props.currentState.yesOrNo;

    //console.log('open modal is', openModalFlag);

    //const location = this.props.location;
    let version = '"Please select a version in the step 1"';

    if (this.props.selectMigrationTarget.version) {
      version = this.props.selectMigrationTarget.version;
    }

    return (
      <Content id="main-content">
        <div className="bx--grid">
          <div className="bx--row">
            <section className="bx--col-lg-10">
              <h2
                style={{
                  //fontWeight: '800',
                  margin: '20px 0',
                  fontSize: '24px',
                }}>
                12. Regression testing
              </h2>
              <p style={{ lineHeight: '23px' }}>
                You can get different results with {version} compared to
                previous compilers if your programs process 'invalid COBOL data' or you have 
                'invalid COBOL programs', where run time data values or programs do not
                conform to the rules in the Enterprise COBOL Language Reference
                manual.
              </p>

              <p style={{ lineHeight: '23px', marginTop: '20px' }}>
                To determine if your programs process invalid COBOL data at run
                time, or have mismatched parameters at run time, compile your
                programs with NUMCHECK, PARMCHECK, SSRANGE, INITCHECK, and
                OPT(0) compiler options and run regression tests.
              </p>

              <p style={{ lineHeight: '23px', marginTop: '20px' }}>
              Do your programs show SSRANGE/NUMCHECK/PARMCHECK run time errors or INITCHECK compile time errors?
              </p>

              <div>
                <Form
                  className="some-class"
                  onSubmit={function noRefCheck() {}}>
                  <div style={{ marginTop: '20px' }}>
                    <FormGroup
                      className="some-class"
                      invalid={false}
                      //legendText="Radio Button heading"
                      message={false}
                      messageText="">
                      <RadioButtonGroup
                        labelPosition="right"
                        name="radio-button-group"
                        onChange={val => {
                          this.props.RegressionTestingButton(val);
                        }}
                        valueSelected={this.props.currentState.yesOrNo}
                        orientation="horizontal">
                        <RadioButton
                          className="some-class"
                          id="radio-1"
                          labelText="No"
                          value="No"
                        />
                        <RadioButton
                          className="some-class"
                          id="radio-2"
                          labelText="Yes"
                          value="Yes"
                        />
                      </RadioButtonGroup>
                    </FormGroup>
                  </div>

                  {this.props.currentState.yesOrNo === 'No' && (
                    <div>
                      <p style={{ lineHeight: '23px', marginTop: '20px' }}>
                        Compile your programs with {version} using NONUMCHECK,
                        NOPARMCHECK, NOSSRANGE and OPT(2), and then run your
                        regression tests alongside the same application in its
                        current form (that is, compiled with Enterprise COBOL 4
                        or earlier compilers).
                      </p>

                      <p style={{ lineHeight: '23px', marginTop: '20px' }}>
                        Do you see different results with {version} compared to
                        the previously used compiler?
                      </p>
                      <div style={{ marginTop: '20px' }}>
                        <FormGroup
                          className="some-class"
                          invalid={false}
                          //legendText="Radio Button heading"
                          message={false}
                          messageText="">
                          <RadioButtonGroup
                            labelPosition="right"
                            name="radio-button-group-2"
                            onChange={val => {
                              this.props.RegressionTestingButton2(val);
                            }}
                            valueSelected={this.props.currentState.yesOrNo2}
                            orientation="horizontal">
                            <RadioButton
                              className="some-class"
                              id="radio-3"
                              labelText="No"
                              value="No"
                            />
                            <RadioButton
                              className="some-class"
                              id="radio-4"
                              labelText="Yes"
                              value="Yes"
                            />
                          </RadioButtonGroup>
                        </FormGroup>
                      </div>

                      {this.props.currentState.yesOrNo2 === 'No' && (
                        <div>
                          <p style={{ lineHeight: '23px', marginTop: '20px' }}>
                            Move the application into production.
                          </p>
                        </div>
                      )}

                      {this.props.currentState.yesOrNo2 === 'Yes' && (
                        <div>
                          <InlineNotification
                            actions={
                              <NotificationActionButton
                                onClick={() => {
                                  this.openModal();
                                }}>
                                Read more
                              </NotificationActionButton>
                            }
                            hideCloseButton={false}
                            iconDescription="describes the close button"
                            kind="warning"
                            notificationType="inline"
                            onCloseButtonClick={function noRefCheck() {}}
                            role="alert"
                            statusIconDescription="describes the status icon"
                            subtitle="Unexpected migration result"
                            title="Migration Issue Detected"
                            lowContrast
                          />
                        </div>
                      )}
                    </div>
                  )}

                  {this.props.currentState.yesOrNo === 'Yes' && (
                  <div>
                    <div>
                  <Accordion align='start'>
                  <AccordionItem title="Types of invalid data">
                  <UnorderedList nested={false}>
                        <ListItem>
                          SSRANGE errors: programs are either:
                          <UnorderedList nested>
                            <ListItem>
                              Subscripting beyond the end of a table
                            </ListItem>
                            <ListItem>
                              Referencing data beyond a COBOL group with
                              reference modification
                            </ListItem>
                            <ListItem>
                              Using an OCCURS DEPENDING ON (ODO) table with the
                              ODO object not in the valid range
                            </ListItem>
                          </UnorderedList>
                        </ListItem>
                        <ListItem>
                          NUMCHECK errors: Programs have invalid data in numeric
                          USAGE DISPLAY (zoned decimal), PACKED-DECIMAL
                          (COMP-3), or BINARY (COMP or COMP-4) data items at run
                          time
                        </ListItem>
                        <ListItem>
                          INITCHECK errors: Programs have uninitialized data
                          items that are used without being initialized
                        </ListItem>
                      </UnorderedList>
                  </AccordionItem>
                </Accordion>
                </div>

                      <div>
                      <Accordion align='start'>
                      <AccordionItem title="Types of invalid programs">
                      <UnorderedList nested={false}>
                        <ListItem>
                          PARMCHECK errors: Programs have a mismatch in size of
                          arguments passed and parameters received from CALLing
                          programs to CALLed programs leading to corruption of
                          data beyond the end of WORKING-STORAGE.
                        </ListItem>
                      </UnorderedList>
                      </AccordionItem>
                    </Accordion>
                    </div>

                      <div>
                      <Accordion align='start'>
                      <AccordionItem title="Action required for invalid data">
                      <UnorderedList nested={false}>
                        <ListItem>
                          Invalid data for subscripts or OCCURS DEPENDING ON
                          (SSRANGE errors):
                          <UnorderedList nested>
                            <ListItem>
                              Change programs to not subscript beyond the end of
                              a table
                            </ListItem>
                            <ListItem>
                              Change programs to not reference data beyond a
                              COBOL group with reference modification
                            </ListItem>
                            <ListItem>
                              Change programs to always have the ODO object in
                              the valid range for ODO tables
                            </ListItem>
                          </UnorderedList>
                        </ListItem>
                        <ListItem>
                          Invalid numeric DISPLAY or PACKED-DECIMAL data items
                          (NUMCHECK errors):
                          <UnorderedList nested>
                            <ListItem>
                              Correct the data sources or programs to only use
                              valid data; or
                            </ListItem>
                            {version.includes('6.1') && (
							<ListItem>
                              Tolerate the invalid data in numeric DISPLAY data
                              items by using the compiler option ZONEDATA
                            </ListItem>
							)}
							{version.includes('6.2') && (
							<ListItem>
                              Tolerate the invalid data in numeric DISPLAY data
                              items by using the INVDATA compiler option. Specify INVDATA suboptions 
							  in accordance with the NUMPROC option you used in COBOL 4.2 or earlier. 
							  For details on how to set the INVDATA and NUMPROC options, see <a
                          href="https://www.ibm.com/docs/en/cobol-zos/6.2?topic=options-invdata"
                          rel="noopener noreferrer"
                          target="_blank">
                          Table 1
                          </a> in the INVDATA documentation.
                            </ListItem>
							)}
							{version.includes('6.3') && (
							<ListItem>
                              Tolerate the invalid data in numeric DISPLAY data
                              items by using the INVDATA compiler option. Specify INVDATA suboptions 
							  in accordance with the NUMPROC option you used in COBOL 4.2 or earlier. 
							  For details on how to set the INVDATA and NUMPROC options, see <a
                          href="https://www.ibm.com/docs/en/cobol-zos/6.3?topic=options-invdata"
                          rel="noopener noreferrer"
                          target="_blank">
                          Table 1
                          </a> in the INVDATA documentation.
                            </ListItem>
							)}
							{version.includes('6.4') && (
							<ListItem>
                              Tolerate the invalid data in numeric DISPLAY data
                              items by using the INVDATA compiler option. Specify INVDATA suboptions 
							  in accordance with the NUMPROC option you used in COBOL 4.2 or earlier. 
							  For details on how to set the INVDATA and NUMPROC options, see <a
                          href="https://www.ibm.com/docs/en/cobol-zos/6.4?topic=options-invdata"
                          rel="noopener noreferrer"
                          target="_blank">
                          Table 1
                          </a> in the INVDATA documentation.
                            </ListItem>
							)}
                          </UnorderedList>
                        </ListItem>
                        <ListItem>
                          Invalid numeric BINARY data items (NUMCHECK errors):
                          Correct the data sources or programs to only use valid
                          data
                        </ListItem>
                        <ListItem>
                          Uninitialized data items (INITCHECK errors): Assign a
                          value to the data item (use MOVE, INITIALIZE, or VALUE
                          clause) before using it as a sender
                        </ListItem>
                      </UnorderedList>
                      </AccordionItem>
                    </Accordion>
                    </div>

                      <div>
                      <Accordion align='start'>
                      <AccordionItem title="Action required for invalid programs">
                      <UnorderedList nested={false}>
                        <ListItem>
                          Invalid parameter usage (PARMCHECK errors):
                          <UnorderedList nested>
                            <ListItem>
                              Correct the calling program to pass a larger
                              argument to the subprogram (match the size)
                            </ListItem>
                            <ListItem>
                              Correct the called subprogram to receive a smaller
                              parameter (match the size) or to modify less of
                              the parameter
                            </ListItem>
                          </UnorderedList>
                        </ListItem>
                      </UnorderedList>
                      </AccordionItem>
                    </Accordion>
                    </div>
                    
                      <p
                        style={{
                          lineHeight: '20px',
                          marginTop: '20px',
                          fontWeight: 'bold',
                        }}>
                        Update ALL source programs and/or data untill there are
                        no more SSRANGE, NUMCHECK, PARMCHECK, or INITCHECK
                        errors.
                      </p>

                      <p
                        style={{
                          lineHeight: '20px',
                          marginTop: '20px',
                        }}>
                        Compile your programs with {version} using NONUMCHECK,
                        NOPARMCHECK, NOSSRANGE and OPT(2), and then run your
                        regression tests alongside the same application in its
                        current form (that is, compiled with Enterprise COBOL 4
                        or earlier compilers).
                      </p>

                      <p style={{ lineHeight: '23px', marginTop: '20px' }}>
                        Do you see different results with {version} compared to
                        the previously used compiler?
                      </p>
                      <div style={{ marginTop: '20px' }}>
                        <FormGroup
                          className="some-class"
                          invalid={false}
                          //legendText="Radio Button heading"
                          message={false}
                          messageText="">
                          <RadioButtonGroup
                            labelPosition="right"
                            name="radio-button-group-2"
                            onChange={val => {
                              this.props.RegressionTestingButton2(val);
                            }}
                            valueSelected={this.props.currentState.yesOrNo2}
                            orientation="horizontal">
                            <RadioButton
                              className="some-class"
                              id="radio-3"
                              labelText="No"
                              value="No"
                            />
                            <RadioButton
                              className="some-class"
                              id="radio-4"
                              labelText="Yes"
                              value="Yes"
                            />
                          </RadioButtonGroup>
                        </FormGroup>
                      </div>

                      {this.props.currentState.yesOrNo2 === 'No' && (
                        <div>
                          <p style={{ lineHeight: '23px', marginTop: '20px' }}>
                            Move the application into production.
                          </p>
                        </div>
                      )}

                      {this.props.currentState.yesOrNo2 === 'Yes' && (
                        <div>
                          <InlineNotification
                            actions={
                              <NotificationActionButton
                                onClick={() => {
                                  this.openModal();
                                }}>
                                Read more
                              </NotificationActionButton>
                            }
                            hideCloseButton={false}
                            iconDescription="describes the close button"
                            kind="warning"
                            notificationType="inline"
                            onCloseButtonClick={function noRefCheck() {}}
                            role="alert"
                            statusIconDescription="describes the status icon"
                            subtitle="Unexpected migration result"
                            title="Migration Issue Detected"
                            lowContrast
                          />
                        </div>
                      )}
                    </div>
                  )}

                  <div
                    style={{
                      alignItems: 'center',
                      display: 'flex',
                      flexWrap: 'wrap',
                      marginTop: '20px',
                      float: 'right',
                      paddingBottom: '48px',
                    }}>
                    <Button
                      className="some-class"
                      disabled={false}
                      kind="secondary"
                      tabIndex={0}
                      type="submit"
                      onClick={this.goBackButton}>
                      Go back
                    </Button>

                    <Button
                      style={{ marginLeft: '10px' }}
                      className="some-class"
                      disabled={false}
                      kind="primary"
                      tabIndex={0}
                      type="submit"
                      onClick={this.proceedButton}>
                      Proceed
                    </Button>
                  </div>
                </Form>
              </div>
            </section>
          </div>
        </div>

        <Modal
          className={regModalClass}
          hasScrollingContent={false}
          iconDescription="Close"
          modalAriaLabel="A label to be read by screen readers on the modal root node"
          modalHeading="Unexpected migration result"
          modalLabel="IBM Enterprise COBOL for z/OS Migration Issue Detected"
          onBlur={function noRefCheck() {}}
          onClick={function noRefCheck() {}}
          onFocus={function noRefCheck() {}}
          onKeyDown={function noRefCheck() {}}
          onRequestClose={this.closeModal}
          onRequestSubmit={function noRefCheck() {}}
          onSecondarySubmit={function noRefCheck() {}}
          open={openModalFlag}
          passiveModal
          primaryButtonDisabled={false}
          primaryButtonText="Primary Button"
          secondaryButtonText="Secondary Button"
          selectorPrimaryFocus="[data-modal-primary-focus]"
          size={undefined}>
          <p className="bx--modal-content__text">
            Runtime results should not be different between Enterprise COBOL 6
            compared to previous COBOL compilers when there are no INITCHECK
            errors at compile time and no NUMCHECK, PARMCHECK, and SSRANGE
            errors found at run time. If your programs do not get INITCHECK
            errors at compile time and no NUMCHECK, PARMCHECK, and SSRANGE
            errors were found at run time, but the results are different with
            COBOL 6, then you may have found a defect in the IBM Enterprise
            COBOL compiler.
          </p>
          <p style={{ marginTop: '20px' }}>
            For this instance, analyze the differences, i.e. get details on
            COBOL statements, data item contents, etc, and call IBM service to
            report it as a possible IBM defect.
          </p>
          <p style={{ marginTop: '20px' }}>
            Additional resources can also be found on our support page.
          </p>
        </Modal>
      </Content>
    );
  }
}
