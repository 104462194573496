import React from 'react';

import {
  Form,
  FormGroup,
  Button,
  RadioButtonGroup,
  RadioButton,
  Modal,
  InlineNotification,
  TextArea,
} from 'carbon-components-react';

import { Content } from 'carbon-components-react/lib/components/UIShell';
import WarningFilled16 from '@carbon/icons-react/lib/warning--filled/16';

import * as actions from '../../../actions';

export class CompletePage extends React.Component {
  proceedButton = () => {
    this.props.proceedButtonCompletePage();
  };

  goBackButton = () => {};

  closeModal = () => {
    this.props.closeModal();
  };

  submitFeedback = () => {
    this.props.submitFeedback();
  };

  selectNPSScore = value => {
    this.props.selectNPSScore(value);
  };

  selectNPSScore2 = value => {
    this.props.selectNPSScore2(value);
  };

  updateNPScomment = (value, valid) => {
    this.props.updateNPScomment(value, valid);
  };

  closeError = () => {
    this.props.closeError();
  };

  render() {
    let textAreaInValid =
      this.props.currentState.comment.length > 500 ? true : false;
    let borderStyle = {};
    let borderStyle2 = {};

    borderStyle = this.props.currentState.showError1
      ? { border: 'solid red', marginTop: '20px' }
      : { marginTop: '20px' };

    borderStyle2 = this.props.currentState.showError2
      ? { border: 'solid red', marginTop: '20px' }
      : { marginTop: '20px' };

    //const location = this.props.location;
    return (
      <Content id="main-content">
        <div className="bx--grid">
          <div className="bx--row">
            <section className="bx--col-lg-13">
              <h2
                style={{
                  //fontWeight: '800',
                  margin: '20px 0',
                  fontSize: '24px',
                }}>
                Congratulations! You have succesfully completed the IBM
                Enterprise COBOL for z/OS migration
              </h2>
              <p style={{ lineHeight: '20px' }}>
                Thank you for using the IBM Enterprise COBOL Migration
                Assistant!
              </p>

              <p style={{ lineHeight: '20px', marginTop: '20px' }}>
                You can now go back and modify your selections, or you can view
                your migration progress.
              </p>

              <div>
                <Form
                  className="some-class"
                  onSubmit={function noRefCheck() {}}>
                  <div
                    style={{
                      alignItems: 'center',
                      display: 'flex',
                      flexWrap: 'wrap',
                      marginTop: '20px',
                      float: 'right',
                      paddingBottom: '48px',
                    }}>
                    {/*<Button
                      className="some-class"
                      disabled={false}
                      kind="secondary"
                      tabIndex={0}
                      type="submit"
                      onClick={this.goBackButton}>
                      Go back
                    </Button> */}

                    <Button
                      style={{ marginLeft: '10px' }}
                      className="some-class"
                      disabled={false}
                      kind="primary"
                      tabIndex={0}
                      type="submit"
                      onClick={this.proceedButton}>
                      View Summary
                    </Button>
                  </div>
                </Form>
              </div>
            </section>
          </div>
        </div>

        <Modal
          className="NPSModal"
          hasScrollingContent={false}
          focusTrap={false}
          iconDescription="Close the modal"
          modalAriaLabel="A label to be read by screen readers on the modal root node"
          modalHeading="Tell us what you think"
          onBlur={function noRefCheck() {}}
          onClick={function noRefCheck() {}}
          onFocus={function noRefCheck() {}}
          onKeyDown={function noRefCheck() {}}
          onRequestClose={this.closeModal}
          onRequestSubmit={this.submitFeedback}
          onSecondarySubmit={this.closeModal}
          open={this.props.currentState.showModal}
          passiveModal={false}
          primaryButtonDisabled={false}
          primaryButtonText="Submit"
          secondaryButtonText="Cancel"
          selectorPrimaryFocus="[data-modal-primary-focus]">
          <div>
            <p
              style={{ marginTop: '20px', marginLeft: '10px' }}
              className="body-short-02">
              How likely are you to recommend the COBOL Migration Assistant to a
              colleague or business partner?
            </p>

            <div style={borderStyle}>
              <div style={{ padding: '8px 8px 8px 0px' }}>
                <div class="bx--row">
                  <div class="bx--col">
                    <p className="body-short-01" style={{ marginLeft: '10px' }}>
                      Not at all likely
                    </p>
                  </div>
                  <div class="bx--col">
                    <p style={{ float: 'right' }} className="body-short-01">
                      Extremely likely
                    </p>
                  </div>
                </div>
                <FormGroup>
                  <RadioButtonGroup
                    onChange={val => this.selectNPSScore(val)}
                    name="radio-button-group"
                    className="feedbackRatioGroup">
                    <RadioButton value="0" labelText="0" id="radio-0" />
                    <RadioButton value="1" labelText="1" id="radio-1" />
                    <RadioButton value="2" labelText="2" id="radio-2" />
                    <RadioButton value="3" labelText="3" id="radio-3" />
                    <RadioButton value="4" labelText="4" id="radio-4" />
                    <RadioButton value="5" labelText="5" id="radio-5" />
                    <RadioButton value="6" labelText="6" id="radio-6" />
                    <RadioButton value="7" labelText="7" id="radio-7" />
                    <RadioButton value="8" labelText="8" id="radio-8" />
                    <RadioButton value="9" labelText="9" id="radio-9" />
                    <RadioButton value="10" labelText="10" id="radio-10" />
                  </RadioButtonGroup>
                </FormGroup>
              </div>
            </div>
            {this.props.currentState.showError1 && (
              <div style={{ display: 'flex', padding: '8px' }}>
                <WarningFilled16 style={{ fill: 'red' }} />{' '}
                <p
                  className="helper-text-01"
                  style={{ color: 'red', marginLeft: '8px' }}>
                  Please select an option
                </p>
              </div>
            )}
          </div>

          <div>
            <p
              style={{ marginTop: '20px', marginLeft: '10px' }}
              className="body-short-02">
              What are the reasons for your score? (optional)
              <FormGroup className="textArea">
              <TextArea
                invalid={textAreaInValid}
                cols={50}
                //helperText="Optional helper text here; if message is more than one line text should wrap (~100 character count maximum)"
                id="test5"
                invalidText="Maximum 500 characters"
                //labelText="Send us your ideas and suggestions."
                placeholder="Type answer here"
                rows={4}
                onChange={event =>
                  this.updateNPScomment(event.target.value, !textAreaInValid)
                }
              />
            </FormGroup>
            </p>
          </div>

          <div style={{ marginLeft: '10px' }}>
            {this.props.currentState.showError && (
              <InlineNotification
                lowContrast
                kind="error"
                iconDescription="describes the close button"
                subtitle={this.props.currentState.errorMessage}
                title="Error"
                onClick={() => this.closeError}
              />
            )}
            {this.props.currentState.showSuccess && (
              <InlineNotification
                lowContrast
                kind="success"
                iconDescription="describes the close button"
                subtitle={this.props.currentState.successMessage}
                title="Success"
                onClick={() => {}}
              />
            )}
          </div>
        </Modal>
      </Content>
    );
  }
}
