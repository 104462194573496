/*
IBM Confidential
IBM Automatic Binary Optimizer for z/OS Trial Cloud Service
Copyright IBM Corp. 2020
The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.

*/

//import require lib
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';

//import reducers for auth and rental
import { datasetsReducer } from './dataset-reducer';
import { authReducer } from './auth-reducer';
import { agreementReducer } from './agreement-reducer';

//all the reducers available in redux store
export const init = () => {
  const reducer = combineReducers({
    datasets: datasetsReducer,
    auth: authReducer,
    agreement: agreementReducer,
  });

  //for redux devtools
  //refer to https://github.com/zalmoxisus/redux-devtools-extension
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  //create redux store
  const store = createStore(reducer, composeEnhancers(applyMiddleware(thunk)));

  //return redux store
  return store;
};
