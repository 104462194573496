import React from 'react';
import {
  Header,
  HeaderName,
  HeaderNavigation,
  HeaderMenuItem,
  HeaderGlobalBar,
  HeaderGlobalAction,
  SkipToContent,
} from 'carbon-components-react/lib/components/UIShell';
import Notification20 from '@carbon/icons-react/lib/notification/20';
import UserAvatar20 from '@carbon/icons-react/lib/user--avatar/20';
import AppSwitcher20 from '@carbon/icons-react/lib/app-switcher/20';

import { ToastNotification } from 'carbon-components-react';

import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

class TutorialHeader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showNotification: false,
    };
  }

  render() {
    return (
      <Header aria-label="Carbon Tutorial">
        <SkipToContent />
        <HeaderName element={Link} to="/" prefix="IBM">
          COBOL Migration Assistant
        </HeaderName>
        <HeaderNavigation aria-label="Carbon Tutorial">
          <HeaderMenuItem element={Link} to="/">
            Home
          </HeaderMenuItem>

          {this.props.auth && (
            <HeaderMenuItem element={Link} to="/assistant">
              Assistant
            </HeaderMenuItem>
          )}

          {this.props.auth && (
            <HeaderMenuItem element={Link} to="/summary">
              Migration progress
            </HeaderMenuItem>
          )}

          {this.props.auth && (
            <HeaderMenuItem element={Link} to="/support">
              Support
            </HeaderMenuItem>
          )}

          {this.props.auth && (
            <HeaderMenuItem element={Link} to="/feedback">
              Feedback
            </HeaderMenuItem>
          )}
        </HeaderNavigation>
        <HeaderGlobalBar>
          <HeaderGlobalAction
            aria-label="Notifications"
            onClick={() => {
              console.log('I am clicking the notification icon');
              this.setState({
                showNotification: true,
              });
            }}>
            <Notification20 />
          </HeaderGlobalAction>
        </HeaderGlobalBar>

        {this.state.showNotification && (
          <div className="header--notification">
            <ToastNotification
              lowContrast
              kind="info"
              caption=""
              iconDescription="describes the close button"
              subtitle={
                <span>
                  IBM Enterprise COBOL for z/OS 4, 5, 6.1, and 6.2 End of Support (EOS) dates
                  have been announced. {' '}
                  <div style={{ marginTop: '20px' }}>
                    <a href="https://www.ibm.com/support/pages/lifecycle/details?q45=R109684Z50598F65" target="_blank">
                      Learn more
                    </a>
                  </div>
                </span>
                }
              timeout={0}
              title="News"
              onCloseButtonClick={() => {
                //console.log('I am clicking the notification icon');
                this.setState({ showNotification: false });
              }}
            />
          </div>
        )}
      </Header>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth.isAuth,
  };
}

export default withRouter(connect(mapStateToProps)(TutorialHeader));
