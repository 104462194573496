import React from 'react';
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Tabs,
  Tab,
} from 'carbon-components-react';

import IconRightArrow from '@carbon/icons-react/lib/arrow--right/20';

import migration_assistant_diagram from './migration_assistant_diagram_wht.png';

const props = {
  tabs: {
    selected: 0,
    triggerHref: '#',
    role: 'navigation',
  },
  tab: {
    href: '#',
    role: 'presentation',
    tabIndex: 0,
  },
};

const LandingPage = () => {
  return (
    <div class="bx--grid">
      <div class="bx--row">
        <div class="bx--col-lg-8 bx--col-md-4 bx--col-sm-2">
          <h2 className="landing-page__subheading">
            Welcome to the IBM Enterprise COBOL Migration Assistant
          </h2>
        </div>
        <div class="bx--col-lg-8 bx--col-md-4 bx--col-sm-2"></div>
      </div>

      <div class="bx--row">
        <div class="bx--col-lg-12">
          <p className="landing-page__p">
            This website provides an interactive checklist that can help you
            navigate through the migration process from older IBM COBOL
            compilers to IBM Enterprise COBOL for z/OS 6.2, 6.3, or 6.4.
          </p>
          <p className="landing-page__p">
            Note: COBOL 6 is compatible with COBOL 5. If migration to COBOL 5
            has already started and you would like to move to COBOL 6, any
            programs compiled with COBOL 5 do not need to be recompiled. Any
            programs that require source code updates from COBOL 5 to COBOL 6
            can be recompiled in COBOL 6 with consistent results.
          </p>
        </div>
        <div class="bx--col-lg-4"></div>
      </div>

      <div class="bx--row">
        <div class="bx--col-lg-9">
          <img
            width="100%"
            height="100%"
            src={migration_assistant_diagram}
            alt="migration_assistant_diagram"
          />
        </div>
        <div class="bx--col-lg-7"></div>
      </div>

      <div className="login-page-button">
        {' '}
        <a href="/appid/login">
          <Button
            renderIcon={IconRightArrow}
            className="start_button"
            disabled={false}
            kind="primary"
            tabIndex={0}
            type="submit">
            Launch assistant
          </Button>
        </a>
      </div>
    </div>
  );
};

export default LandingPage;
