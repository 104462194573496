import React from 'react';

import {
  Form,
  FormGroup,
  Button,
  RadioButtonGroup,
  RadioButton,
} from 'carbon-components-react';

import { Content } from 'carbon-components-react/lib/components/UIShell';

export class VerifyMigration extends React.Component {
  proceedButton = () => {
    this.props.proceedButtonVerifyMigration();
  };

  goBackButton = () => {
    this.props.backButtonVerifyMigration();
  };

  render() {
    //const location = this.props.location;
    return (
      <Content id="main-content">
        <div className="bx--grid">
          <div className="bx--row">
            <section className="bx--col-lg-10">
              <h2
                style={{
                  //fontWeight: '800',
                  margin: '20px 0',
                  fontSize: '24px',
                }}>
                13. Verify migration process
              </h2>
              <p style={{ lineHeight: '20px' }}>
                Are there additional problems you are encountering that were not
                resolved?
              </p>

              <div>
                <Form
                  className="some-class"
                  onSubmit={function noRefCheck() {}}>
                  <div style={{ marginTop: '20px' }}>
                    <FormGroup
                      className="some-class"
                      invalid={false}
                      //legendText="Radio Button heading"
                      message={false}
                      messageText="">
                      <RadioButtonGroup
                        labelPosition="right"
                        name="radio-button-group"
                        onChange={val => {
                          this.props.VerifyMigrationButton(val);
                        }}
                        valueSelected={this.props.currentState.yesOrNo}
                        orientation="horizontal">
                        <RadioButton
                          className="some-class"
                          id="radio-1"
                          labelText="No"
                          value="No"
                        />
                        <RadioButton
                          className="some-class"
                          id="radio-2"
                          labelText="Yes"
                          value="Yes"
                        />
                      </RadioButtonGroup>
                    </FormGroup>
                  </div>

                  {this.props.currentState.yesOrNo === 'Yes' && (
                    <div>
                      <p>
                        For further assistance, visit the{' '}
                        <a
                          href="https://www.ibm.com/community/z/languages/cobol/discussions/"
                          rel="noopener noreferrer"
                          target="_blank">
                          COBOL Community group
                        </a>{' '}
                        on the IBM Z and LinuxONE Community website.
                      </p>
                    </div>
                  )}

                  <div
                    style={{
                      alignItems: 'center',
                      display: 'flex',
                      flexWrap: 'wrap',
                      marginTop: '20px',
                      float: 'right',
                      paddingBottom: '48px',
                    }}>
                    <Button
                      className="some-class"
                      disabled={false}
                      kind="secondary"
                      tabIndex={0}
                      type="submit"
                      onClick={this.goBackButton}>
                      Go back
                    </Button>

                    <Button
                      style={{ marginLeft: '10px' }}
                      className="some-class"
                      disabled={false}
                      kind="primary"
                      tabIndex={0}
                      type="submit"
                      onClick={this.proceedButton}>
                      Proceed
                    </Button>
                  </div>
                </Form>
              </div>
            </section>
          </div>
        </div>
      </Content>
    );
  }
}
