import React from 'react';
import {
  Form,
  FormGroup,
  Button,
  TextInput,
  TextArea,
  Select,
  SelectItem,
  RadioButtonGroup,
  RadioButton,
  InlineNotification,
} from 'carbon-components-react';

import WarningFilled16 from '@carbon/icons-react/lib/warning--filled/16';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as actions from '../../actions';

class FeedbackPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      score1: '',
      score2: '',
      comment: '',
      isValid: true,
      showError: false,
      showSuccess: false,
      showError1: false,
      showError2: false,
      errorMessage: '',
      successMessage: '',
    };
  }

  async componentDidMount() {
    await this.props.dispatch(actions.checkAuth());

    if (this.props.id) {
      console.log('I am login:', this.props.id);

      //console.log('after updated current step:', this.state.currentStep);
    } else {
      window.location.href = '/appid/login';
    }
  }

  submitFeedback = () => {
    if (this.state.score1 === '') {
      this.setState({
        showError1: true,
        showSuccess: false,
      });
    } else if (this.state.score2 === '') {
      this.setState({
        showError2: true,
        showSuccess: false,
      });
    } else if (!this.state.isValid) {
      this.setState({
        showError: true,
        errorMessage: 'Feedback message must not exceed 500 characters',
      });
    } else {
      //console.log('all the inputs are valid!');

      actions
        .updateFeedback([
          this.props.id,
          this.state.score1,
          this.state.score2,
          this.state.comment,
        ])
        .then(
          data => {
            console.log('successfully update the database', data);

            this.setState({
              showSuccess: true,
              showError: false,
              successMessage: 'Feedback submitted successfully',
            });
          },
          err => {
            console.log('fail to update the database:', err);
            this.setState({
              showError: true,
              showSuccess: false,
              errorMessage:
                err
                  .toString()
                  .localeCompare(
                    'Error: Request failed with status code 429'
                  ) === 0
                  ? 'You are submitting too often. Please try again later!'
                  : 'Fail to update to database. Error message:' + err,
            });
          }
        );
    }
  };

  render() {
    let textAreaInValid = this.state.comment.length > 500 ? true : false;

    let borderStyle = {};
    let borderStyle2 = {};

    borderStyle = this.state.showError1
      ? { border: 'solid red', marginTop: '20px' }
      : { marginTop: '20px' };

    borderStyle2 = this.state.showError2
      ? { border: 'solid red', marginTop: '20px' }
      : { marginTop: '20px' };

    return (
      <div className="bx--grid bx--grid--full-width landing-page">
        <div class="bx--row">
          <div class="bx--col-lg-8 bx--col-md-4 bx--col-sm-2">
            <h3
              className="productive-heading-03"
              style={{
                //fontWeight: '800',
                margin: '20px 10px',
              }}>
              Tell us what you think
            </h3>

            <Form>
              <div>
                <p
                  style={{ marginTop: '20px', marginLeft: '10px' }}
                  className="body-short-02">
                  Overall, how well does IBM COBOL Migration Assistant meet your
                  migration needs?
                </p>

                <div style={borderStyle}>
                  <div style={{ padding: '8px 8px 8px 0px' }}>
                    <div class="bx--row">
                      <div class="bx--col">
                        <p
                          className="body-short-01"
                          style={{ marginLeft: '10px' }}>
                          Not at all
                        </p>
                      </div>
                      <div class="bx--col">
                        <p style={{ float: 'right' }} className="body-short-01">
                          Very well
                        </p>
                      </div>
                    </div>
                    <FormGroup>
                      <RadioButtonGroup
                        onChange={val => {
                          //console.log('radio button on change value:', val);
                          this.setState({
                            score1: val,
                            showError1: false,
                          });
                        }}
                        name="radio-button-group"
                        className="feedbackRatioGroup">
                        <RadioButton value="0" labelText="0" id="radio-0" />
                        <RadioButton value="1" labelText="1" id="radio-1" />
                        <RadioButton value="2" labelText="2" id="radio-2" />
                        <RadioButton value="3" labelText="3" id="radio-3" />
                        <RadioButton value="4" labelText="4" id="radio-4" />
                        <RadioButton value="5" labelText="5" id="radio-5" />
                        <RadioButton value="6" labelText="6" id="radio-6" />
                        <RadioButton value="7" labelText="7" id="radio-7" />
                        <RadioButton value="8" labelText="8" id="radio-8" />
                        <RadioButton value="9" labelText="9" id="radio-9" />
                        <RadioButton value="10" labelText="10" id="radio-10" />
                      </RadioButtonGroup>
                    </FormGroup>
                  </div>
                </div>
                {this.state.showError1 && (
                  <div style={{ display: 'flex', padding: '8px' }}>
                    <WarningFilled16 style={{ fill: 'red' }} />{' '}
                    <p
                      className="helper-text-01"
                      style={{ color: 'red', marginLeft: '8px' }}>
                      Please select an option
                    </p>
                  </div>
                )}
              </div>

              <div>
                <p
                  style={{ marginTop: '20px', marginLeft: '10px' }}
                  className="body-short-02">
                  Overall, how satisfied are you with IBM COBOL Migration
                  Assistant?
                </p>

                <div style={borderStyle2}>
                  <div style={{ padding: '8px 8px 8px 0px' }}>
                    <div class="bx--row">
                      <div class="bx--col">
                        <p
                          className="body-short-01"
                          style={{ marginLeft: '10px' }}>
                          Very dissatisfied
                        </p>
                      </div>
                      <div class="bx--col">
                        <p style={{ float: 'right' }} className="body-short-01">
                          Very satisfied
                        </p>
                      </div>
                    </div>
                    <FormGroup>
                      <RadioButtonGroup
                        onChange={val => {
                          //console.log('radio button on change value:', val);
                          this.setState({
                            score2: val,
                            showError2: false,
                          });
                        }}
                        name="radio-button-group2"
                        className="feedbackRatioGroup">
                        <RadioButton value="0" labelText="0" id="radio2-0" />
                        <RadioButton value="1" labelText="1" id="radio2-1" />
                        <RadioButton value="2" labelText="2" id="radio2-2" />
                        <RadioButton value="3" labelText="3" id="radio2-3" />
                        <RadioButton value="4" labelText="4" id="radio2-4" />
                        <RadioButton value="5" labelText="5" id="radio2-5" />
                        <RadioButton value="6" labelText="6" id="radio2-6" />
                        <RadioButton value="7" labelText="7" id="radio2-7" />
                        <RadioButton value="8" labelText="8" id="radio2-8" />
                        <RadioButton value="9" labelText="9" id="radio2-9" />
                        <RadioButton value="10" labelText="10" id="radio2-10" />
                      </RadioButtonGroup>
                    </FormGroup>
                  </div>
                </div>
                {this.state.showError2 && (
                  <div style={{ display: 'flex', padding: '8px' }}>
                    <WarningFilled16 style={{ fill: 'red' }} />{' '}
                    <p
                      className="helper-text-01"
                      style={{ color: 'red', marginLeft: '8px' }}>
                      Please select an option
                    </p>
                  </div>
                )}
              </div>

              <div>
                <p
                  style={{ marginTop: '20px', marginLeft: '10px' }}
                  className="body-short-02">
                  How can we improve IBM COBOL Migration Assistant? (optional)
                </p>
                <FormGroup className="textArea">
                  <TextArea
                    invalid={textAreaInValid}
                    cols={50}
                    //helperText="Optional helper text here; if message is more than one line text should wrap (~100 character count maximum)"
                    id="test5"
                    invalidText="Maximum 500 characters"
                    labelText="Send us your ideas and suggestions."
                    placeholder="Type answer here"
                    rows={4}
                    onChange={event => {
                      console.log('text area value is:', event.target.value);
                      this.setState({
                        comment: event.target.value,
                        isValid: !textAreaInValid,
                      });
                    }}
                  />
                </FormGroup>
              </div>

              <div style={{ marginLeft: '10px' }}>
                {this.state.showError && (
                  <InlineNotification
                    lowContrast
                    kind="error"
                    iconDescription="describes the close button"
                    subtitle={this.state.errorMessage}
                    title="Error"
                    onClick={() => {
                      this.setState({
                        showError: false,
                      });
                    }}
                  />
                )}
                {this.state.showSuccess && (
                  <InlineNotification
                    lowContrast
                    kind="success"
                    iconDescription="describes the close button"
                    subtitle={this.state.successMessage}
                    title="Success"
                    onClick={() => {
                      this.setState({
                        showSuccess: false,
                      });
                    }}
                  />
                )}
              </div>
            </Form>

            <Button
              style={{ marginTop: '40px', marginLeft: '10px' }}
              kind="primary"
              tabIndex={0}
              type="submit"
              onClick={this.submitFeedback}>
              Submit feedback
            </Button>
          </div>
          <div class="bx--col-lg-8 bx--col-md-4 bx--col-sm-2"></div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  //console.log('current state:', state);
  //debugger;
  return {
    datasets: state.datasets.data[0],
    token: state.auth.token,
    id: state.auth.id,
    name: state.auth.name,
  };
};

export default withRouter(connect(mapStateToProps)(FeedbackPage));
