import React from 'react';

const SupportPage = () => {
  return (
    <div className="bx--grid bx--grid--full-width landing-page">
      <div className="bx--row landing-page__r2">
        <div className="bx--col bx--no-gutter">
          <div className="bx--grid bx--grid--no-gutter bx--grid--full-width">
            <div className="bx--row landing-page__tab-content">
              <div className="bx--col-md-5 bx--col-lg-12">
                <h2
                  className="productive-heading-03"
                  style={{
                    //fontWeight: '800',
                    margin: '10px 0',
                    fontSize: '24px',
                  }}>
                  IBM Enterprise COBOL for z/OS Migration Assistant Support
                </h2>

                <div
                  style={{
                    fontWeight: '800',
                    margin: '30px 0',
                  }}>
                  <h2 className="productive-heading-02">COBOL migration and performance tuning webinars</h2>
                  <p style={{ marginTop: '10px' }} className="body-short-02">
                    Watch the <a
                      href="https://www.ibm.com/support/knowledgecenter/en/SS6SG3_latest/migration-webinars.html"
                      rel="noopener noreferrer"
                      target="_blank">
                      recorded COBOL migration and performance tuning webinar videos
                    </a> or connect with IBM COBOL experts by filling out a questionnaire.
                  </p>
                </div>

                <div
                  style={{
                    fontWeight: '800',
                    margin: '30px 0',
                  }}>
                  <h2 className="productive-heading-02">
                    Complete migration guides
                  </h2>
                  {/* <p style={{ marginTop: '10px' }} className="body-short-02">
                    {' '}
                    <a
                      href="http://publibfp.dhe.ibm.com/epubs/pdf/igy6mg10.pdf"
                      rel="noopener noreferrer"
                      target="_blank">
                      Migration to Enterprise 6.1
                    </a>
                  </p> */}
                  <p className="body-short-02">
                    {' '}
                    <a
                      href="http://publibfp.dhe.ibm.com/epubs/pdf/igy6mg20.pdf"
                      rel="noopener noreferrer"
                      target="_blank">
                      Migration to Enterprise 6.2
                    </a>
                  </p>
                  <p className="body-short-02">
                    {' '}
                    <a
                      href="http://publibfp.dhe.ibm.com/epubs/pdf/igy6mg30.pdf"
                      rel="noopener noreferrer"
                      target="_blank">
                      Migration to Enterprise 6.3
                    </a>
                  </p>
				  <p className="body-short-02">
                    {' '}
                    <a
                      href="http://publibfp.dhe.ibm.com/epubs/pdf/igy6mg40.pdf"
                      rel="noopener noreferrer"
                      target="_blank">
                      Migration to Enterprise 6.4
                    </a>
                  </p>
                </div>

                <div
                  style={{
                    fontWeight: '800',
                    margin: '30px 0',
                  }}>
                  <h2 className="productive-heading-02">Migration FAQ</h2>
                  <p style={{ marginTop: '10px' }} className="body-short-02">
                    Frequently asked questions and answers for Enterprise COBOL
                    migrations.
                  </p>
                  {/* <p style={{ marginTop: '10px' }} className="body-short-02">
                    <a
                      href="http://www.ibm.com/support/knowledgecenter/en/SS6SG3_6.1.0/com.ibm.cobol61.ent.doc/migrate/igymapxa001.html"
                      rel="noopener noreferrer"
                      target="_blank">
                      FAQ for migration to COBOL 6.1
                    </a>
                  </p> */}
                  <p className="body-short-02">
                    <a
                      href="http://www.ibm.com/support/knowledgecenter/en/SS6SG3_6.2.0/com.ibm.cobol62.ent.doc/migrate/igymapxa001.html"
                      rel="noopener noreferrer"
                      target="_blank">
                      FAQ for migration to COBOL 6.2
                    </a>
                  </p>
                  <p className="body-short-02">
                    <a
                      href="http://www.ibm.com/support/knowledgecenter/en/SS6SG3_6.3.0/migrate/igymapxa001.html"
                      rel="noopener noreferrer"
                      target="_blank">
                      FAQ for migration to COBOL 6.3
                    </a>
                  </p>
				  <p className="body-short-02">
                    <a
                      href="http://www.ibm.com/support/knowledgecenter/en/SS6SG3_6.4.0/migrate/igymapxa001.html"
                      rel="noopener noreferrer"
                      target="_blank">
                      FAQ for migration to COBOL 6.4
                    </a>
                  </p>
                </div>

                <div
                  style={{
                    fontWeight: '800',
                    margin: '30px 0',
                  }}>
                  <h2 className="productive-heading-02">Resources</h2>
                  <p style={{ marginTop: '10px' }} className="body-short-02">
                    <a
                      href="https://www.ibm.com/support/pages/node/6208968"
                      rel="noopener noreferrer"
                      target="_blank">
                      COBOL Applications: Techniques to Make them Efficient
                    </a>
                  </p>
                  <p className="body-short-02">
                    <a
                      href="https://mediacenter.ibm.com/media/1_q99kohqg"
                      rel="noopener noreferrer"
                      target="_blank">
                      Debugging Non-Optimized and Optimized COBOL Programs with
                      IBM Debug for z Systems
                    </a>
                  </p>
                </div>

                <div
                  style={{
                    fontWeight: '800',
                    margin: '30px 0',
                  }}>
                  <h2 className="productive-heading-02">COBOL Support</h2>
                  <p style={{ marginTop: '10px' }} className="body-short-02">
                    How can we help you?
                  </p>
                  <p className="body-short-02">
                    {' '}
                    <a
                      href="https://www.ibm.com/community/z/languages/cobol/discussions/"
                      rel="noopener noreferrer"
                      target="_blank">
                      IBM COBOL Community
                    </a>
                  </p>
                  <p className="body-short-02">
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href={`mailto:compclds@ca.ibm.com`}>
                      compclds@ca.ibm.com
                    </a>
                  </p>
                  </div>

                  <div
                  style={{
                    fontWeight: '800',
                    margin: '30px 0',
                  }}>
                  <h2 className="productive-heading-02">
Copyright and trademark information</h2>
<p style={{ marginTop: '10px' }} className="body-short-02">IBM, the IBM logo, IBM Z, Language Environment, z9, z10, z13, z13s, z15, z16, zEnterprise, and z/OS are trademarks or registered trademarks of International Business Machines Corporation, in the United States and/or other countries. Other product and service names might be trademarks of IBM or other companies. 
A current list of IBM trademarks is available on <a
                      href="https://www.ibm.com/legal/copytrade"
                      rel="noopener noreferrer"
                      target="_blank">
                      https://www.ibm.com/legal/copytrade
                    </a>.
                    </p>
<p style={{ marginTop: '10px' }} className="body-short-02">Java and all Java-based trademarks and logos are trademarks or registered trademarks of Oracle and/or
its affiliates.
</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupportPage;
