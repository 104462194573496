import React from 'react';

import {
  Form,
  FormGroup,
  Button,
  RadioButtonGroup,
  RadioButton,
  UnorderedList,
  ListItem,
} from 'carbon-components-react';

import { Content } from 'carbon-components-react/lib/components/UIShell';

export class UpdateCOBOLSource extends React.Component {
  proceedButton = () => {
    this.props.proceedButtonUpdateCOBOLSource();
  };

  goBackButton = () => {
    this.props.backButtonUpdateCOBOLSource();
  };

  render() {
    //const location = this.props.location;

    let version = '';

    if (this.props.selectMigrationTarget.version) {
      version = this.props.selectMigrationTarget.version;
    }

    return (
      <Content id="main-content">
        <div className="bx--grid">
          <div className="bx--row">
            <section className="bx--col-lg-10">
              <h2
                style={{
                  //fontWeight: '800',
                  margin: '20px 0',
                  fontSize: '24px',
                }}>
                11. Update COBOL source code
              </h2>
              <p style={{ lineHeight: '20px' }}>
                Are ALL your programs compiled with Enterprise COBOL 3.4 or
                later?
              </p>

              <div>
                <Form
                  className="some-class"
                  onSubmit={function noRefCheck() {}}>
                  <div style={{ marginTop: '20px' }}>
                    <FormGroup
                      className="some-class"
                      invalid={false}
                      //legendText="Radio Button heading"
                      message={false}
                      messageText="">
                      <RadioButtonGroup
                        labelPosition="right"
                        name="radio-button-group"
                        onChange={val => {
                          this.props.UpdateCOBOLSourceButton(val);
                        }}
                        valueSelected={this.props.currentState.yesOrNo}
                        orientation="horizontal">
                        <RadioButton
                          className="some-class"
                          id="radio-1"
                          labelText="No"
                          value="No"
                        />
                        <RadioButton
                          className="some-class"
                          id="radio-2"
                          labelText="Yes"
                          value="Yes"
                        />
                      </RadioButtonGroup>
                    </FormGroup>
                  </div>

                  <div>
                    {this.props.currentState.yesOrNo === 'No' &&
                      version === 'IBM Enterprise COBOL for z/OS 5.2' && (
                        <div>
                          <p style={{ lineHeight: '20px' }}>
                            Depending on which COBOL compiler you are migrating
                            from, you might need to make source code changes.
                          </p>

                          <p
                            style={{
                              lineHeight: '20px',
                              marginTop: '20px',
                              marginBottom: '20px',
                            }}>
                            For information on how to make source code changes,
                            select the COBOL compiler your program was compiled
                            with:
                          </p>

                          <UnorderedList>
                            <ListItem>
                              {' '}
                              <a
                                href="http://www.ibm.com/support/knowledgecenter/SS6SG3_5.2.0/com.ibm.cobol52.ent.doc/migrate/igymch1000.html"
                                rel="noopener noreferrer"
                                target="_blank">
                                OS/VS COBOL
                              </a>
                            </ListItem>
                            <ListItem>
                              {' '}
                              <a
                                href="http://www.ibm.com/support/knowledgecenter/SS6SG3_5.2.0/com.ibm.cobol52.ent.doc/migrate/igymch1200.html"
                                rel="noopener noreferrer"
                                target="_blank">
                                VS COBOL II
                              </a>
                            </ListItem>
                            <ListItem>
                              {' '}
                              <a
                                href="http://www.ibm.com/support/knowledgecenter/SS6SG3_5.2.0/com.ibm.cobol52.ent.doc/migrate/igymch1400.html"
                                rel="noopener noreferrer"
                                target="_blank">
                                COBOL/370 1.1
                              </a>
                            </ListItem>
                            <ListItem>
                              {' '}
                              <a
                                href="http://www.ibm.com/support/knowledgecenter/SS6SG3_5.2.0/com.ibm.cobol52.ent.doc/migrate/igymch1400.html"
                                rel="noopener noreferrer"
                                target="_blank">
                                COBOL for MVS™ & VM, 1.2
                              </a>
                            </ListItem>
                            <ListItem>
                              {' '}
                              <a
                                href="http://www.ibm.com/support/knowledgecenter/SS6SG3_5.2.0/com.ibm.cobol52.ent.doc/migrate/igymch1400.html"
                                rel="noopener noreferrer"
                                target="_blank">
                                COBOL for OS/390 & VM, 2.1
                              </a>
                            </ListItem>
                            <ListItem>
                              {' '}
                              <a
                                href="http://www.ibm.com/support/knowledgecenter/SS6SG3_5.2.0/com.ibm.cobol52.ent.doc/migrate/igymch1400.html"
                                rel="noopener noreferrer"
                                target="_blank">
                                COBOL for OS/390 & VM, 2.2
                              </a>
                            </ListItem>
                            <ListItem>
                              {' '}
                              <a
                                href="http://www.ibm.com/support/knowledgecenter/SS6SG3_5.2.0/com.ibm.cobol52.ent.doc/migrate/igymuv3.html"
                                rel="noopener noreferrer"
                                target="_blank">
                                Enterprise COBOL for z/OS and OS/390, 3.1
                              </a>
                            </ListItem>
                            <ListItem>
                              {' '}
                              <a
                                href="http://www.ibm.com/support/knowledgecenter/SS6SG3_5.2.0/com.ibm.cobol52.ent.doc/migrate/igymuv3.html"
                                rel="noopener noreferrer"
                                target="_blank">
                                Enterprise COBOL for z/OS and OS/390, 3.2
                              </a>
                            </ListItem>
                            <ListItem>
                              {' '}
                              <a
                                href="http://www.ibm.com/support/knowledgecenter/SS6SG3_5.2.0/com.ibm.cobol52.ent.doc/migrate/igymuv3.html"
                                rel="noopener noreferrer"
                                target="_blank">
                                Enterprise COBOL for z/OS, 3.3
                              </a>
                            </ListItem>
                          </UnorderedList>

                          <p style={{ lineHeight: '20px', marginTop: '20px' }}>
                            Have all required source code changes been made?
                          </p>

                          <div style={{ marginTop: '20px' }}>
                            <FormGroup
                              className="some-class"
                              invalid={false}
                              //legendText="Radio Button heading"
                              message={false}
                              messageText="">
                              <RadioButtonGroup
                                labelPosition="right"
                                name="radio-button-group2"
                                onChange={val => {
                                  this.props.UpdateCOBOLSourceButton2(val);
                                }}
                                valueSelected={this.props.currentState.yesOrNo2}
                                orientation="horizontal">
                                <RadioButton
                                  className="some-class"
                                  id="radio-3"
                                  labelText="No"
                                  value="No"
                                />
                                <RadioButton
                                  className="some-class"
                                  id="radio-4"
                                  labelText="Yes"
                                  value="Yes"
                                />
                              </RadioButtonGroup>
                            </FormGroup>
                          </div>
                        </div>
                      )}

                    {this.props.currentState.yesOrNo === 'No' &&
                      version === 'IBM Enterprise COBOL for z/OS 6.1' && (
                        <div>
                          <p style={{ lineHeight: '20px' }}>
                            Depending on which COBOL compiler you are migrating
                            from, you might need to make source code changes.
                          </p>

                          <p
                            style={{
                              lineHeight: '20px',
                              marginTop: '20px',
                              marginBottom: '20px',
                            }}>
                            For information on how to make source code changes,
                            select the COBOL compiler your program was compiled
                            with:
                          </p>

                          <UnorderedList>
                            <ListItem>
                              {' '}
                              <a
                                href="https://www.ibm.com/support/knowledgecenter/SS6SG3_6.1.0/migrate/igymch1000.html"
                                rel="noopener noreferrer"
                                target="_blank">
                                OS/VS COBOL
                              </a>
                            </ListItem>
                            <ListItem>
                              {' '}
                              <a
                                href="http://www.ibm.com/support/knowledgecenter/SS6SG3_6.1.0/migrate/igymch1200.html"
                                rel="noopener noreferrer"
                                target="_blank">
                                VS COBOL II
                              </a>
                            </ListItem>
                            <ListItem>
                              {' '}
                              <a
                                href="http://www.ibm.com/support/knowledgecenter/SS6SG3_6.1.0/migrate/igymch1400.html"
                                rel="noopener noreferrer"
                                target="_blank">
                                COBOL/370 1.1
                              </a>
                            </ListItem>
                            <ListItem>
                              {' '}
                              <a
                                href="https://www.ibm.com/support/knowledgecenter/SS6SG3_6.1.0/migrate/igymch1400.html"
                                rel="noopener noreferrer"
                                target="_blank">
                                COBOL for MVS & VM 1.2
                              </a>
                            </ListItem>
                            <ListItem>
                              {' '}
                              <a
                                href="http://www.ibm.com/support/knowledgecenter/SS6SG3_6.1.0/migrate/igymch1400.html"
                                rel="noopener noreferrer"
                                target="_blank">
                                COBOL for OS/390 & VM 2.1
                              </a>
                            </ListItem>
                            <ListItem>
                              {' '}
                              <a
                                href="http://www.ibm.com/support/knowledgecenter/SS6SG3_6.1.0/migrate/igymch1400.html"
                                rel="noopener noreferrer"
                                target="_blank">
                                COBOL for OS/390 & VM 2.2
                              </a>
                            </ListItem>
                            <ListItem>
                              {' '}
                              <a
                                href="http://www.ibm.com/support/knowledgecenter/SS6SG3_6.1.0/migrate/igymuv3.html"
                                rel="noopener noreferrer"
                                target="_blank">
                                Enterprise COBOL for z/OS and OS/390 3.1
                              </a>
                            </ListItem>
                            <ListItem>
                              {' '}
                              <a
                                href="http://www.ibm.com/support/knowledgecenter/SS6SG3_6.1.0/migrate/igymuv3.html"
                                rel="noopener noreferrer"
                                target="_blank">
                                Enterprise COBOL for z/OS and OS/390 3.2
                              </a>
                            </ListItem>
                            <ListItem>
                              {' '}
                              <a
                                href="http://www.ibm.com/support/knowledgecenter/SS6SG3_6.1.0/migrate/igymuv3.html"
                                rel="noopener noreferrer"
                                target="_blank">
                                Enterprise COBOL for z/OS 3.3
                              </a>
                            </ListItem>
                          </UnorderedList>

                          <p style={{ lineHeight: '20px', marginTop: '20px' }}>
                            Have all required source code changes been made?
                          </p>

                          <div style={{ marginTop: '20px' }}>
                            <FormGroup
                              className="some-class"
                              invalid={false}
                              //legendText="Radio Button heading"
                              message={false}
                              messageText="">
                              <RadioButtonGroup
                                labelPosition="right"
                                name="radio-button-group2"
                                onChange={val => {
                                  this.props.UpdateCOBOLSourceButton2(val);
                                }}
                                valueSelected={this.props.currentState.yesOrNo2}
                                orientation="horizontal">
                                <RadioButton
                                  className="some-class"
                                  id="radio-3"
                                  labelText="No"
                                  value="No"
                                />
                                <RadioButton
                                  className="some-class"
                                  id="radio-4"
                                  labelText="Yes"
                                  value="Yes"
                                />
                              </RadioButtonGroup>
                            </FormGroup>
                          </div>
                        </div>
                      )}

                    {this.props.currentState.yesOrNo === 'No' &&
                      version === 'IBM Enterprise COBOL for z/OS 6.2' && (
                        <div>
                          <p style={{ lineHeight: '20px' }}>
                            Depending on which COBOL compiler you are migrating
                            from, you might need to make source code changes.
                          </p>

                          <p
                            style={{
                              lineHeight: '20px',
                              marginTop: '20px',
                              marginBottom: '20px',
                            }}>
                            For information on how to make source code changes,
                            select the COBOL compiler your program was compiled
                            with:
                          </p>

                          <UnorderedList>
                            <ListItem>
                              {' '}
                              <a
                                href="http://www.ibm.com/support/knowledgecenter/SS6SG3_6.2.0/migrate/igymch1000.html"
                                rel="noopener noreferrer"
                                target="_blank">
                                OS/VS COBOL
                              </a>
                            </ListItem>
                            <ListItem>
                              {' '}
                              <a
                                href="http://www.ibm.com/support/knowledgecenter/SS6SG3_6.2.0/migrate/igymch1200.html"
                                rel="noopener noreferrer"
                                target="_blank">
                                VS COBOL II
                              </a>
                            </ListItem>
                            <ListItem>
                              {' '}
                              <a
                                href="http://www.ibm.com/support/knowledgecenter/SS6SG3_6.2.0/migrate/igymch1400.html"
                                rel="noopener noreferrer"
                                target="_blank">
                                COBOL/370 1.1
                              </a>
                            </ListItem>
                            <ListItem>
                              {' '}
                              <a
                                href="http://www.ibm.com/support/knowledgecenter/SS6SG3_6.2.0/migrate/igymch1400.html"
                                rel="noopener noreferrer"
                                target="_blank">
                                COBOL for MVS & VM 1.2
                              </a>
                            </ListItem>
                            <ListItem>
                              {' '}
                              <a
                                href="http://www.ibm.com/support/knowledgecenter/SS6SG3_6.2.0/migrate/igymch1400.html"
                                rel="noopener noreferrer"
                                target="_blank">
                                COBOL for OS/390 & VM 2.1
                              </a>
                            </ListItem>
                            <ListItem>
                              {' '}
                              <a
                                href="http://www.ibm.com/support/knowledgecenter/SS6SG3_6.2.0/migrate/igymch1400.html"
                                rel="noopener noreferrer"
                                target="_blank">
                                COBOL for OS/390 & VM 2.2
                              </a>
                            </ListItem>
                            <ListItem>
                              {' '}
                              <a
                                href="http://www.ibm.com/support/knowledgecenter/SS6SG3_6.2.0/migrate/igymuv3.html"
                                rel="noopener noreferrer"
                                target="_blank">
                                Enterprise COBOL for z/OS and OS/390 3.1
                              </a>
                            </ListItem>
                            <ListItem>
                              {' '}
                              <a
                                href="http://www.ibm.com/support/knowledgecenter/SS6SG3_6.2.0/migrate/igymuv3.html"
                                rel="noopener noreferrer"
                                target="_blank">
                                Enterprise COBOL for z/OS and OS/390 3.2
                              </a>
                            </ListItem>
                            <ListItem>
                              {' '}
                              <a
                                href="http://www.ibm.com/support/knowledgecenter/SS6SG3_6.2.0/migrate/igymuv3.html"
                                rel="noopener noreferrer"
                                target="_blank">
                                Enterprise COBOL for z/OS 3.3
                              </a>
                            </ListItem>
                          </UnorderedList>

                          <p style={{ lineHeight: '20px', marginTop: '20px' }}>
                            Have all required source code changes been made?
                          </p>

                          <div style={{ marginTop: '20px' }}>
                            <FormGroup
                              className="some-class"
                              invalid={false}
                              //legendText="Radio Button heading"
                              message={false}
                              messageText="">
                              <RadioButtonGroup
                                labelPosition="right"
                                name="radio-button-group2"
                                onChange={val => {
                                  this.props.UpdateCOBOLSourceButton2(val);
                                }}
                                valueSelected={this.props.currentState.yesOrNo2}
                                orientation="horizontal">
                                <RadioButton
                                  className="some-class"
                                  id="radio-3"
                                  labelText="No"
                                  value="No"
                                />
                                <RadioButton
                                  className="some-class"
                                  id="radio-4"
                                  labelText="Yes"
                                  value="Yes"
                                />
                              </RadioButtonGroup>
                            </FormGroup>
                          </div>
                        </div>
                      )}

                    {this.props.currentState.yesOrNo === 'No' &&
                      version === 'IBM Enterprise COBOL for z/OS 6.3' && (
                        <div>
                          <p style={{ lineHeight: '20px' }}>
                            Depending on which COBOL compiler you are migrating
                            from, you might need to make source code changes.
                          </p>

                          <p
                            style={{
                              lineHeight: '20px',
                              marginTop: '20px',
                              marginBottom: '20px',
                            }}>
                            For information on how to make source code changes,
                            select the COBOL compiler your program was compiled
                            with:
                          </p>

                          <UnorderedList>
                            <ListItem>
                              {' '}
                              <a
                                href="http://www.ibm.com/support/knowledgecenter/SS6SG3_6.3.0/migrate/igymch1000.html"
                                rel="noopener noreferrer"
                                target="_blank">
                                OS/VS COBOL
                              </a>
                            </ListItem>
                            <ListItem>
                              {' '}
                              <a
                                href="http://www.ibm.com/support/knowledgecenter/SS6SG3_6.3.0/migrate/igymch1200.html"
                                rel="noopener noreferrer"
                                target="_blank">
                                VS COBOL II
                              </a>
                            </ListItem>
                            <ListItem>
                              {' '}
                              <a
                                href="http://www.ibm.com/support/knowledgecenter/SS6SG3_6.3.0/migrate/igymch1400.html"
                                rel="noopener noreferrer"
                                target="_blank">
                                COBOL/370 1.1
                              </a>
                            </ListItem>
                            <ListItem>
                              {' '}
                              <a
                                href="http://www.ibm.com/support/knowledgecenter/SS6SG3_6.3.0/migrate/igymch1400.html"
                                rel="noopener noreferrer"
                                target="_blank">
                                COBOL for MVS & VM 1.2
                              </a>
                            </ListItem>
                            <ListItem>
                              {' '}
                              <a
                                href="http://www.ibm.com/support/knowledgecenter/SS6SG3_6.3.0/migrate/igymch1400.html"
                                rel="noopener noreferrer"
                                target="_blank">
                                COBOL for OS/390 & VM 2.1
                              </a>
                            </ListItem>
                            <ListItem>
                              {' '}
                              <a
                                href="http://www.ibm.com/support/knowledgecenter/SS6SG3_6.3.0/migrate/igymch1400.html"
                                rel="noopener noreferrer"
                                target="_blank">
                                COBOL for OS/390 & VM 2.2
                              </a>
                            </ListItem>
                            <ListItem>
                              {' '}
                              <a
                                href="http://www.ibm.com/support/knowledgecenter/SS6SG3_6.3.0/migrate/igymuv3.html"
                                rel="noopener noreferrer"
                                target="_blank">
                                Enterprise COBOL for z/OS and OS/390 3.1
                              </a>
                            </ListItem>
                            <ListItem>
                              {' '}
                              <a
                                href="http://www.ibm.com/support/knowledgecenter/SS6SG3_6.3.0/migrate/igymuv3.html"
                                rel="noopener noreferrer"
                                target="_blank">
                                Enterprise COBOL for z/OS and OS/390 3.2
                              </a>
                            </ListItem>
{/*                             <ListItem>
                              {' '}
                              <a
                                href="http://www.ibm.com/support/knowledgecenter/SS6SG3_6.3.0/migrate/igymuv3.html"
                                rel="noopener noreferrer"
                                target="_blank">
                                Enterprise COBOL for z/OS 3.2
                              </a>
                            </ListItem> */}
                            <ListItem>
                              {' '}
                              <a
                                href="http://www.ibm.com/support/knowledgecenter/SS6SG3_6.3.0/migrate/igymuv3.html"
                                rel="noopener noreferrer"
                                target="_blank">
                                Enterprise COBOL for z/OS 3.3
                              </a>
                            </ListItem>
                          </UnorderedList>

                          <p style={{ lineHeight: '20px', marginTop: '20px' }}>
                            Have all required source code changes been made?
                          </p>

                          <div style={{ marginTop: '20px' }}>
                            <FormGroup
                              className="some-class"
                              invalid={false}
                              //legendText="Radio Button heading"
                              message={false}
                              messageText="">
                              <RadioButtonGroup
                                labelPosition="right"
                                name="radio-button-group2"
                                onChange={val => {
                                  this.props.UpdateCOBOLSourceButton2(val);
                                }}
                                valueSelected={this.props.currentState.yesOrNo2}
                                orientation="horizontal">
                                <RadioButton
                                  className="some-class"
                                  id="radio-3"
                                  labelText="No"
                                  value="No"
                                />
                                <RadioButton
                                  className="some-class"
                                  id="radio-4"
                                  labelText="Yes"
                                  value="Yes"
                                />
                              </RadioButtonGroup>
                            </FormGroup>
                          </div>
                        </div>
                      )}
					  
					 {this.props.currentState.yesOrNo === 'No' &&
                      version === 'IBM Enterprise COBOL for z/OS 6.4' && (
                        <div>
                          <p style={{ lineHeight: '20px' }}>
                            Depending on which COBOL compiler you are migrating
                            from, you might need to make source code changes.
                          </p>

                          <p
                            style={{
                              lineHeight: '20px',
                              marginTop: '20px',
                              marginBottom: '20px',
                            }}>
                            For information on how to make source code changes,
                            select the COBOL compiler your program was compiled
                            with:
                          </p>

                          <UnorderedList>
                            <ListItem>
                              {' '}
                              <a
                                href="http://www.ibm.com/support/knowledgecenter/SS6SG3_6.4.0/migrate/igymch1000.html"
                                rel="noopener noreferrer"
                                target="_blank">
                                OS/VS COBOL
                              </a>
                            </ListItem>
                            <ListItem>
                              {' '}
                              <a
                                href="http://www.ibm.com/support/knowledgecenter/SS6SG3_6.4.0/migrate/igymch1200.html"
                                rel="noopener noreferrer"
                                target="_blank">
                                VS COBOL II
                              </a>
                            </ListItem>
                            <ListItem>
                              {' '}
                              <a
                                href="http://www.ibm.com/support/knowledgecenter/SS6SG3_6.4.0/migrate/igymch1400.html"
                                rel="noopener noreferrer"
                                target="_blank">
                                COBOL/370 1.1
                              </a>
                            </ListItem>
                            <ListItem>
                              {' '}
                              <a
                                href="http://www.ibm.com/support/knowledgecenter/SS6SG3_6.4.0/migrate/igymch1400.html"
                                rel="noopener noreferrer"
                                target="_blank">
                                COBOL for MVS & VM 1.2
                              </a>
                            </ListItem>
                            <ListItem>
                              {' '}
                              <a
                                href="http://www.ibm.com/support/knowledgecenter/SS6SG3_6.4.0/migrate/igymch1400.html"
                                rel="noopener noreferrer"
                                target="_blank">
                                COBOL for OS/390 & VM 2.1
                              </a>
                            </ListItem>
                            <ListItem>
                              {' '}
                              <a
                                href="http://www.ibm.com/support/knowledgecenter/SS6SG3_6.4.0/migrate/igymch1400.html"
                                rel="noopener noreferrer"
                                target="_blank">
                                COBOL for OS/390 & VM 2.2
                              </a>
                            </ListItem>
                            <ListItem>
                              {' '}
                              <a
                                href="http://www.ibm.com/support/knowledgecenter/SS6SG3_6.4.0/migrate/igymuv3.html"
                                rel="noopener noreferrer"
                                target="_blank">
                                Enterprise COBOL for z/OS and OS/390 3.1
                              </a>
                            </ListItem>
                            <ListItem>
                              {' '}
                              <a
                                href="http://www.ibm.com/support/knowledgecenter/SS6SG3_6.4.0/migrate/igymuv3.html"
                                rel="noopener noreferrer"
                                target="_blank">
                                Enterprise COBOL for z/OS and OS/390 3.2
                              </a>
                            </ListItem>
{/*                             <ListItem>
                              {' '}
                              <a
                                href="http://www.ibm.com/support/knowledgecenter/SS6SG3_6.4.0/migrate/igymuv3.html"
                                rel="noopener noreferrer"
                                target="_blank">
                                Enterprise COBOL for z/OS 3.2
                              </a>
                            </ListItem> */}
                            <ListItem>
                              {' '}
                              <a
                                href="http://www.ibm.com/support/knowledgecenter/SS6SG3_6.4.0/migrate/igymuv3.html"
                                rel="noopener noreferrer"
                                target="_blank">
                                Enterprise COBOL for z/OS 3.3
                              </a>
                            </ListItem>
                          </UnorderedList>

                          <p style={{ lineHeight: '20px', marginTop: '20px' }}>
                            Have all required source code changes been made?
                          </p>

                          <div style={{ marginTop: '20px' }}>
                            <FormGroup
                              className="some-class"
                              invalid={false}
                              //legendText="Radio Button heading"
                              message={false}
                              messageText="">
                              <RadioButtonGroup
                                labelPosition="right"
                                name="radio-button-group2"
                                onChange={val => {
                                  this.props.UpdateCOBOLSourceButton2(val);
                                }}
                                valueSelected={this.props.currentState.yesOrNo2}
                                orientation="horizontal">
                                <RadioButton
                                  className="some-class"
                                  id="radio-3"
                                  labelText="No"
                                  value="No"
                                />
                                <RadioButton
                                  className="some-class"
                                  id="radio-4"
                                  labelText="Yes"
                                  value="Yes"
                                />
                              </RadioButtonGroup>
                            </FormGroup>
                          </div>
                        </div>
                      )}
                  </div>

                  <div
                    style={{
                      alignItems: 'center',
                      display: 'flex',
                      flexWrap: 'wrap',
                      marginTop: '20px',
                      marginRight: '1rem',
                      float: 'right',
                      paddingBottom: '48px',
                    }}>
                    <Button
                      className="some-class"
                      disabled={false}
                      kind="secondary"
                      tabIndex={0}
                      type="submit"
                      onClick={this.goBackButton}>
                      Go back
                    </Button>

                    <Button
                      style={{ marginLeft: '10px' }}
                      className="some-class"
                      disabled={false}
                      kind="primary"
                      tabIndex={0}
                      type="submit"
                      onClick={this.proceedButton}>
                      Proceed
                    </Button>
                  </div>
                </Form>
              </div>
            </section>
          </div>
        </div>
      </Content>
    );
  }
}
