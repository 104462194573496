/*
IBM Confidential
IBM Automatic Binary Optimizer for z/OS Trial Cloud Service
Copyright IBM Corp. 2020
The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.

*/

import { LOGIN_FAILURE, LOGIN_SUCCESS, LOGOUT } from '../actions/types';

const INTIAL_STATE = {
  isAuth: false,
  errors: [],
  token: '',
  id: '',
  name: '',
};

export const authReducer = (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        isAuth: true,
        errors: [],
        token: action.token,
        id: action.id,
        name: action.name,
      };
    case LOGIN_FAILURE:
      return { ...state, errors: action.errors };
    case LOGOUT:
      return {
        ...state,
        isAuth: false,
        errors: [],
        token: '',
        id: '',
        name: '',
      };
    default:
      return state;
  }
};
