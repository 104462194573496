import React from 'react';

import {
  Form,
  FormGroup,
  Button,
  RadioButtonGroup,
  RadioButton,
  UnorderedList,
  ListItem,
  Accordion,
  AccordionItem,
} from 'carbon-components-react';

import { Content } from 'carbon-components-react/lib/components/UIShell';

export class UpgradeSoftware extends React.Component {
  proceedButton = () => {
    this.props.proceedButtonUpgradeSoftware();
  };

  goBackButton = () => {
    this.props.backButtonUpgradeSoftware();
  };

  render() {
    let version = '';

    if (this.props.selectMigrationTarget.version) {
      version = this.props.selectMigrationTarget.version;
    }

    //const location = this.props.location;

    const accordion_style = {
      width: '100%',
      marginTop: '20px',
    };

    return (
      <Content id="main-content">
        <div className="bx--grid">
          <div className="bx--row">
            <section className="bx--col-lg-10">
              <h2
                style={{
                  //fontWeight: '800',
                  margin: '20px 0',
                  fontSize: '24px',
                }}>
                5. Upgrade hardware and software levels
              </h2>
              <p style={{ lineHeight: '20px' }}>
                Ensure that all of your hardware and software are up to the prerequisite levels listed as follows. </p>
                
                <div style={{ marginTop: '20px' }}>
                {version.includes('6.1') && (
                <div>
                 <div>
                  <Accordion align='start'>
                  <AccordionItem title="Hardware requirements">
                    <p style={{ lineHeight: '23px' }}>{version} runs on the following IBM Z servers:</p>
                      <UnorderedList nested={false}>
                        <ListItem>
                          z13 or z13s
    </ListItem>
                        <ListItem>
                          zEnterprise EC12 or zEnterprise BC12
    </ListItem>
                        <ListItem>
                          zEnterprise 196 or zEnterprise 114
    </ListItem>
                      </UnorderedList>
                  </AccordionItem>
                </Accordion>
                </div>  

                <div>
                <Accordion align='start'>
                  <AccordionItem title="Software requirements">
                    <p style={{ lineHeight: '23px' }}>{version} runs under the control
of, or in conjunction with, the currently supported releases
of the following programs and their subsequent releases or
their equivalents. For more information on the following
programs listed that require program temporary fixes
(PTFs), refer to the Program Directory and the preventive
service planning (PSP) bucket.</p>
<p>-z/OS 2.1 (5650-ZOS), or later</p>
                  </AccordionItem>
                </Accordion>  
                </div> 

                <div>
                <Accordion align='start'>
                  <AccordionItem title="Optional licensed programs for z/OS">
                  <p style={{ lineHeight: '23px' }}>Depending on the functions used, you may require other software products such as CICS, Db2 or IMS. For
a list of compatible software, see the <a
                          href="https://www.ibm.com/software/reports/compatibility/clarity/index.html"
                          rel="noopener noreferrer"
                          target="_blank">
                          Software Product Compatibility Reports (SPCR)
                  </a> site. </p>
                  <p style={{ marginTop: '20px' }}>From the SPCR web page, in the <span style ={{ fontWeight: 'bold' }}>In-depth reports</span> section, 
                  under <span style ={{ fontWeight: 'bold' }}>Detailed system requirements</span>, 
                  click <span style ={{ fontWeight: 'bold' }}>Create a report</span>. 
                  Search for <span style ={{ fontWeight: 'bold' }}>Enterprise COBOL for z/OS</span>, 
                  choose <span style ={{ fontWeight: 'bold' }}>Version 6.1</span> and then click <span style ={{ fontWeight: 'bold' }}>Submit</span>. </p>
                  </AccordionItem>
                </Accordion>
                </div> 
                </div>  
                )}
                
                {version.includes('6.2') && (
                  <div style={{ marginTop: '20px' }}>
                    <div>
                  <Accordion align='start'>
                  <AccordionItem title="Hardware requirements">
                  <p style={{ lineHeight: '23px' }}>{version} runs on the following IBM Z servers:</p>
                      <UnorderedList nested={false}>
                        <ListItem>
                          z14
    </ListItem>
                        <ListItem>
                          z13 or z13s
    </ListItem>
                        <ListItem>
                          zEnterprise EC12 or zEnterprise BC12
    </ListItem>
                        <ListItem>
                          zEnterprise 196 or zEnterprise 114
    </ListItem>
                      </UnorderedList>
                  </AccordionItem>
                </Accordion>
                </div>                  

                <div>
                <Accordion align='start'>
                  <AccordionItem title="Software requirements">
                  <p style={{ lineHeight: '23px' }}>{version} runs
under the control of, or in conjunction with,
the currently supported releases of the
following programs and their subsequent
releases or their equivalents. For more
information on the following programs
listed that require program temporary fixes
(PTFs), refer to the Program Directory and
the preventive service planning (PSP)
bucket.</p>
                      <UnorderedList nested={false}>
                        <ListItem>
                          z/OS 2.1 (5650-ZOS), or later is required.
    </ListItem>
                        <ListItem>
                          For installation on z/OS, z/OS SMP/E is
                          required.
    </ListItem>
                        <ListItem>
                          For customization during or after
                          installation, z/OS High Level Assembler
                          is required.
    </ListItem>
                        <ListItem>
                          Enterprise COBOL XML PARSE
                          statements in programs, which are
                          compiled with the XMLPARSE(XMLSS)
                          compiler option, require z/OS XML
                          System Services 2.1 (5650-ZOS), or later.
    </ListItem>
                      </UnorderedList>
                <p style={{ marginTop: '20px' }}><span style ={{ fontWeight: 'bold' }}>Note</span>: Prerequisite z/OS levels were valid as of the General Availability of the Enterprise COBOL 6 releases. Over time, some of z/OS versions will announce end of support plans. Check the <a
                          href="https://www.ibm.com/support/pages/lifecycle/search?q=5655-ZOS,%205650-ZOS"
                          rel="noopener noreferrer"
                          target="_blank">z/OS lifecycle</a> site for z/OS versions currently supported.</p>                             
                  </AccordionItem>
                </Accordion>
                </div>  
                
                <div>
                <Accordion align='start'>
                  <AccordionItem title="Optional licensed programs for z/OS">
                  <p style={{ lineHeight: '23px' }}>Depending on the functions used, you may require other software products such as CICS, Db2 or IMS. For
a list of compatible software, see the <a
                          href="https://www.ibm.com/software/reports/compatibility/clarity/index.html"
                          rel="noopener noreferrer"
                          target="_blank">
                          Software Product Compatibility Reports (SPCR)
                  </a> site. </p>
                  <p style={{ marginTop: '20px' }}>From the SPCR web page, in the <span style ={{ fontWeight: 'bold' }}>In-depth reports</span> section, 
                  under <span style ={{ fontWeight: 'bold' }}>Detailed system requirements</span>, 
                  click <span style ={{ fontWeight: 'bold' }}>Create a report</span>. 
                  Search for <span style ={{ fontWeight: 'bold' }}>Enterprise COBOL for z/OS</span>, choose <span style ={{ fontWeight: 'bold' }}>Version 6.2</span> and then click <span style ={{ fontWeight: 'bold' }}>Submit</span>. </p>
                  </AccordionItem>
                </Accordion>
                </div>
                </div>      
                )}

                {version.includes('6.3') && (
                  <div style={{ marginTop: '20px' }}>
                  <div>
                  <Accordion align='start'>
                  <AccordionItem title="Hardware requirements">
                  <p style={{ lineHeight: '23px' }}>{version} runs on the following IBM Z servers:</p>
                      <UnorderedList nested={false}>
                        <ListItem>
                          z15 or z15 T02
</ListItem>
                        <ListItem>
                          z14 or z14 ZR1
</ListItem>
                        <ListItem>
                          z13 or z13s
</ListItem>
                        <ListItem>
                          zEnterprise EC12 or zEnterprise BC12
</ListItem>
                        <ListItem>
                          zEnterprise 196 or zEnterprise 114
</ListItem>
                      </UnorderedList>
                  </AccordionItem>
                </Accordion> 
                </div>
                
                <div>
                <Accordion align='start'>
                  <AccordionItem title="Software requirements">
                  <p style={{ lineHeight: '23px' }}>{version} runs under the control of, or in conjunction with, the currently supported
releases of the following programs and their subsequent releases or their equivalents. For more information on the following programs listed that require program temporary fixes (PTFs), see
the Enterprise COBOL Program Directory and the preventive service planning (PSP) bucket.</p>
<UnorderedList nested={false}>
                        <ListItem>
                        z/OS 2.2 (5650-ZOS), or later is required.
                        <p style={{ lineHeight: '23px' }}>&nbsp;&nbsp;&nbsp;&nbsp;Note: To run 64-bit applications, z/OS 2.3
(5650-ZOS), or later, is required.</p>
    </ListItem>
                        <ListItem>
                        For installation on z/OS, z/OS SMP/E is required.
    </ListItem>
                        <ListItem>
                        For customization during or after installation,
z/OS High Level Assembler is required.
    </ListItem>
                        <ListItem>
                        Enterprise COBOL XML PARSE statements in
programs, which are compiled with the
XMLPARSE(XMLSS) compiler option, require z/OS
XML System Services 2.2 (5650-ZOS), or later.
    </ListItem>
                      </UnorderedList>
                <p style={{ marginTop: '20px' }}><span style ={{ fontWeight: 'bold' }}>Note</span>: Prerequisite z/OS levels were valid as of the General Availability of the Enterprise COBOL 6 releases. Over time, some of z/OS versions will announce end of support plans. Check the <a
                          href="https://www.ibm.com/support/pages/lifecycle/search?q=5655-ZOS,%205650-ZOS"
                          rel="noopener noreferrer"
                          target="_blank">z/OS lifecycle</a> site for z/OS versions currently supported.</p> 
                  </AccordionItem>
                </Accordion>
                </div> 

                <div>
                <Accordion align='start'>
                  <AccordionItem title="Optional licensed programs for z/OS">
                  <p style={{ lineHeight: '23px' }}>Depending on the functions used, you may require other software products such as CICS, Db2 or IMS. For
a list of compatible software, see the <a
                          href="https://www.ibm.com/software/reports/compatibility/clarity/index.html"
                          rel="noopener noreferrer"
                          target="_blank">
                          Software Product Compatibility Reports (SPCR)
</a> site. </p>
<p style={{ marginTop: '20px' }}>From the SPCR web page, in the <span style ={{ fontWeight: 'bold' }}>In-depth reports</span> section, 
                  under <span style ={{ fontWeight: 'bold' }}>Detailed system requirements</span>, 
                  click <span style ={{ fontWeight: 'bold' }}>Create a report</span>. 
                  Search for <span style ={{ fontWeight: 'bold' }}>Enterprise COBOL for z/OS</span>, 
                  choose <span style ={{ fontWeight: 'bold' }}>Version 6.3</span> and then click <span style ={{ fontWeight: 'bold' }}>Submit</span>. </p>
                  </AccordionItem>
                </Accordion> 
                </div>
                </div>
                )}
				
				
                {version.includes('6.4') && (
                  <div style={{ marginTop: '20px' }}>
                  <div>
                  <Accordion align='start'>
                  <AccordionItem title="Hardware requirements">
                  <p style={{ lineHeight: '23px' }}>{version} runs on the following IBM Z servers:</p>
                      <UnorderedList nested={false}>
                        <ListItem>
                          z16
</ListItem>	
						<ListItem>
                          z15 or z15 T02
</ListItem>
                        <ListItem>
                          z14 or z14 ZR1
</ListItem>
                        <ListItem>
                          z13 or z13s
</ListItem>
                        <ListItem>
                          zEnterprise EC12 or zEnterprise BC12
</ListItem>
                      </UnorderedList>
                  </AccordionItem>
                </Accordion> 
                </div>
                
                <div>
                <Accordion align='start'>
                  <AccordionItem title="Software requirements">
                  <p style={{ lineHeight: '23px' }}>{version} runs under the control of, or in conjunction with, the currently supported
releases of the following programs and their subsequent releases or their equivalents. For more information on the following programs listed that require program temporary fixes (PTFs), see
the Enterprise COBOL Program Directory and the preventive service planning (PSP) bucket.</p>
<UnorderedList nested={false}>
                        <ListItem>
                        z/OS 2.3 (5650-ZOS), or later is required.
    </ListItem>
                        <ListItem>
                        For installation on z/OS, z/OS SMP/E is required.
    </ListItem>
                        <ListItem>
                        For customization during or after installation,
z/OS High Level Assembler is required.
    </ListItem>
                        <ListItem>
                        Enterprise COBOL XML PARSE statements in
programs, which are compiled with the
XMLPARSE(XMLSS) compiler option, require z/OS
XML System Services 2.3 (5650-ZOS), or later.
    </ListItem>
                        <ListItem>
                        The new COBOL/Java interoperability feature available in COBOL 6.4 requires IBM
SDK for z/OS, Java Technology Edition 8.0.6.36 (JVM), or IBM Semeru Certified Edition for z/OS 11.0.14.1, or later.
    </ListItem>	
                      </UnorderedList>
                <p style={{ marginTop: '20px' }}><span style ={{ fontWeight: 'bold' }}>Note</span>: Prerequisite z/OS levels were valid as of the General Availability of the Enterprise COBOL 6 releases. Over time, some of z/OS versions will announce end of support plans. Check the <a
                          href="https://www.ibm.com/support/pages/lifecycle/search?q=5655-ZOS,%205650-ZOS"
                          rel="noopener noreferrer"
                          target="_blank">z/OS lifecycle</a> site for z/OS versions currently supported.</p> 
                  </AccordionItem>
                </Accordion>
                </div> 

                <div>
                <Accordion align='start'>
                  <AccordionItem title="Optional licensed programs for z/OS">
                  <p style={{ lineHeight: '23px' }}>Depending on the functions used, you may require other software products such as CICS, Db2 or IMS. For
a list of compatible software, see the <a
                          href="https://www.ibm.com/software/reports/compatibility/clarity/index.html"
                          rel="noopener noreferrer"
                          target="_blank">
                          Software Product Compatibility Reports (SPCR)
</a> site. </p>
<p style={{ marginTop: '20px' }}>From the SPCR web page, in the <span style ={{ fontWeight: 'bold' }}>In-depth reports</span> section, 
                  under <span style ={{ fontWeight: 'bold' }}>Detailed system requirements</span>, 
                  click <span style ={{ fontWeight: 'bold' }}>Create a report</span>. 
                  Search for <span style ={{ fontWeight: 'bold' }}>Enterprise COBOL for z/OS</span>, 
                  choose <span style ={{ fontWeight: 'bold' }}>Version 6.4</span> and then click <span style ={{ fontWeight: 'bold' }}>Submit</span>. </p>
                  </AccordionItem>
                </Accordion> 
                </div>
                </div>
                )}
				
           </div>
                
                {version.length === 0 && (
                  <p>"Please select a version in step 1"</p>
                )}
         
                <p style={{ lineHeight: '23px', marginTop: '20px' }}>Are all of your hardware and software up 
                to the prerequisite levels as defined?</p>

              <div>
                <Form
                  className="some-class"
                  onSubmit={function noRefCheck() { }}>
                  <div style={{ marginTop: '20px' }}>
                    <FormGroup
                      className="some-class"
                      invalid={false}
                      //legendText="Radio Button heading"
                      message={false}
                      messageText="">
                      <RadioButtonGroup
                        labelPosition="right"
                        name="radio-button-group"
                        onChange={value => {
                          this.props.UpgradeSoftwareButton(value);
                          //console.log('choose value is:', value);
                        }}
                        valueSelected={this.props.currentState.yesOrNo}
                        orientation="horizontal">
                        <RadioButton
                          className="some-class"
                          id="radio-1"
                          labelText="No"
                          value="No"
                        />
                        <RadioButton
                          className="some-class"
                          id="radio-2"
                          labelText="Yes"
                          value="Yes"
                        />
                      </RadioButtonGroup>
                    </FormGroup>
                  </div>

                  <div>
                    {this.props.currentState.yesOrNo === 'No' && (
                      <div>
                        <p style={{ lineHeight: '20px', marginTop: '20px' }}>
                          All hardware and software must be updated to the
                          prerequistite levels.
                        </p>
                      </div>
                    )}
                  </div>

                  <div
                    style={{
                      alignItems: 'center',
                      display: 'flex',
                      flexWrap: 'wrap',
                      marginTop: '20px',
                      float: 'right',
                      paddingBottom: '48px',
                    }}>
                    <Button
                      className="some-class"
                      disabled={false}
                      kind="secondary"
                      tabIndex={0}
                      type="submit"
                      onClick={this.goBackButton}>
                      Go back
                    </Button>

                    <Button
                      style={{ marginLeft: '10px' }}
                      className="some-class"
                      disabled={false}
                      kind="primary"
                      tabIndex={0}
                      type="submit"
                      onClick={this.proceedButton}>
                      Proceed
                    </Button>
                  </div>
                </Form>
              </div>
            </section>
          </div>
        </div>
      </Content>
    );
  }
}
