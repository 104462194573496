import React from 'react';

import {
  Form,
  FormGroup,
  Button,
  RadioButtonGroup,
  RadioButton,
  Checkbox,
  UnorderedList,
  ListItem,
} from 'carbon-components-react';

import { Content } from 'carbon-components-react/lib/components/UIShell';

export class CompleteLanguage extends React.Component {
  proceedButton = () => {
    console.log('calling procedd button complete Language');

    this.props.proceedButtonCompleteLanguage();
  };

  goBackButton = () => {
    this.props.backButtonCompleteLanguage();
  };

  render() {
    //const location = this.props.location;
    return (
      <Content id="main-content">
        <div className="bx--grid">
          <div className="bx--row">
            <section className="bx--col-lg-10">
              <h2
                style={{
                  //fontWeight: '800',
                  margin: '20px 0',
                  fontSize: '24px',
                }}>
                4. Complete language environment migration
              </h2>
              <p style={{ lineHeight: '20px' }}>
                Earlier COBOL programs can run under several different runtime
                libraries, including Language Environment (LE). If any of your
                programs are still using old runtime library routines, you need
                to migrate them.
              </p>

              <p style={{ lineHeight: '20px', marginTop: '20px' }}>
                Select ALL actions that have been performed:
              </p>

              <div>
                <Form
                  className="some-class"
                  onSubmit={function noRefCheck() {}}>
                  <div style={{ marginTop: '20px' }}>
                    <FormGroup
                      className="some-class"
                      invalid={false}
                      //legendText="Radio Button heading"
                      message={false}
                      messageText="">
                      <Checkbox
                        className="some-class"
                        defaultChecked={this.props.currentState.option[0]}
                        id="id_0"
                        indeterminate={false}
                        labelText="The Language Environment data set SCEERUN is installed in LNKLST or LPALST."
                        onChange={(value, id, evt) => {
                          //console.log('check box value is', value, id, evt);

                          this.props.CompleteLanguageButton(0, id);
                        }}
                      />
                      <Checkbox
                        className="some-class"
                        defaultChecked={this.props.currentState.option[1]}
                        id="id_1"
                        indeterminate={false}
                        labelText="There are no instances of COBLIB, VSCLLIB or COB2LIB in LNKLST, or LPALST."
                        onChange={(value, id, evt) => {
                          //console.log('check box value is', value, id, evt);

                          this.props.CompleteLanguageButton(1, id);
                        }}
                      />
                      <Checkbox
                        className="some-class"
                        defaultChecked={this.props.currentState.option[2]}
                        id="id_2"
                        indeterminate={false}
                        labelText="There are no instances of COBLIB, VSCLLIB or COB2LIB in JCL STEPLIB or JOBLIB statements in batch jobs or in CICS start up JCL."
                        onChange={(value, id, evt) => {
                          //console.log('check box value is', value, id, evt);

                          this.props.CompleteLanguageButton(2, id);
                        }}
                      />
                      <Checkbox
                        className="some-class"
                        defaultChecked={this.props.currentState.option[3]}
                        id="id_3"
                        indeterminate={false}
                        labelText="All statically bound runtime library routines for programs that are compiled with NORES have been REPLACED with routines from Language Environment."
                        onChange={(value, id, evt) => {
                          //console.log('check box value is', value, id, evt);

                          this.props.CompleteLanguageButton(3, id);
                        }}
                      />
                      <Checkbox
                        className="some-class"
                        defaultChecked={this.props.currentState.option[4]}
                        id="id_4"
                        indeterminate={false}
                        labelText="IGZEBST bootstrap modules for VS COBOL II programs that are compiled with RES were either linked with the VS COBOL II runtime version of IGZEBST that has APAR PN74000 applied, or IGZEBST was REPLACEd with IGZEBST from Language Environment."
                        onChange={(value, id, evt) => {
                          //console.log('check box value is', value, id, evt);

                          this.props.CompleteLanguageButton(4, id);
                        }}
                      />
                      {/* <Checkbox
                        className="some-class"
                        defaultChecked={this.props.currentState.option[5]}
                        id="id_5"
                        indeterminate={false}
                        labelText="Not required"
                        onChange={(value, id, evt) => {
                          //console.log('check box value is', value, id, evt);

                          this.props.CompleteLanguageButton(5, id);
                        }}
                      /> */}
                    </FormGroup>
                  </div>

                  <div>
                    <p style={{ lineHeight: '20px', marginTop: '20px' }}>
                      For applications that include VS COBOL II programs, when
                      introducing Enterprise COBOL 5 or COBOL 6 programs into
                      these applications, take the following actions:
                    </p>
                    <UnorderedList>
                      <ListItem>
                        For non-CICS and statically CALLed CICS programs, relink
                        and REPLACE the IGZEBST modules with IGZEBST from LE
                        with the PTFs for{' '}
                        <a
                          href="https://www.ibm.com/support/pages/node/6202789"
                          rel="noopener noreferrer"
                          target="_blank">
                          APAR PI33330
                        </a>{' '}
                        installed.{' '}
                      </ListItem>

                      <ListItem>
                        For dynamically CALLed CICS programs, install the PTFs
                        for{' '}
                        <a
                          href="http://www-01.ibm.com/support/docview.wss?uid=isg1PI25079"
                          rel="noopener noreferrer"
                          target="_blank">
                          APAR PI25079
                        </a>{' '}
                        into SCEERUN.
                      </ListItem>
                    </UnorderedList>
                  </div>

                  <div
                    style={{
                      alignItems: 'center',
                      display: 'flex',
                      flexWrap: 'wrap',
                      marginTop: '20px',
                      float: 'right',
                      paddingBottom: '48px',
                    }}>
                    <Button
                      className="some-class"
                      disabled={false}
                      kind="secondary"
                      tabIndex={0}
                      type="submit"
                      onClick={this.goBackButton}>
                      Go back
                    </Button>

                    <Button
                      style={{ marginLeft: '10px' }}
                      className="some-class"
                      disabled={false}
                      kind="primary"
                      tabIndex={0}
                      type="submit"
                      onClick={this.proceedButton}>
                      Proceed
                    </Button>
                  </div>
                </Form>
              </div>
            </section>
          </div>
        </div>
      </Content>
    );
  }
}
