import React from 'react';

import {
  Form,
  Button,
  UnorderedList,
  ListItem,
  OrderedList,
} from 'carbon-components-react';

import { Content } from 'carbon-components-react/lib/components/UIShell';

export class SetDefaultCompiler extends React.Component {
  proceedButton = () => {
    this.props.proceedButtonSetDefaultCompiler();
  };

  goBackButton = () => {
    this.props.backButtonSetDefaultCompiler();
  };

  render() {
    //const location = this.props.location;
    let version = '"Please select a version in the first step"';

    if (this.props.selectMigrationTarget.version) {
      version = this.props.selectMigrationTarget.version;
    }

    let link =
      'http://www.ibm.com/support/knowledgecenter/SS6SG3_6.3.0/custom/igycch102.html';
    if (version === 'IBM Enterprise COBOL for z/OS 6.3') {
      link =
        'http://www.ibm.com/support/knowledgecenter/SS6SG3_6.3.0/custom/igycch102.html';
    } else if (version === 'IBM Enterprise COBOL for z/OS 6.2') {
      link =
        'http://www.ibm.com/support/knowledgecenter/SS6SG3_6.2.0/custom/igycch102.html';
    } else if (version === 'IBM Enterprise COBOL for z/OS 6.1') {
      link =
        'http://www.ibm.com/support/knowledgecenter/SS6SG3_6.1.0/custom/igycch102.html';
    } else if (version === 'IBM Enterprise COBOL for z/OS 6.4') {
      link =
        'http://www.ibm.com/support/knowledgecenter/SS6SG3_6.4.0/custom/igycch102.html';
    }

    //const location = this.props.location;
    return (
      <Content id="main-content">
        <div className="bx--grid">
          <div className="bx--row">
            <section className="bx--col-lg-10">
              <h2
                style={{
                  //fontWeight: '800',
                  margin: '20px 0',
                  fontSize: '24px',
                }}>
                10. Set default compiler options
              </h2>
              <p style={{ lineHeight: '20px' }}>
                <a href={link} rel="noopener noreferrer" target="_blank">
                  Set the default compiler options
                </a>{' '}
                in {version} to be compatible with previous COBOL compilers.
                This involves copying IGYCDOPT source from SIGYSAMP, setting the
                options, and then assembling the modified IGYCDOPT.
              </p>

              <p style={{ lineHeight: '20px', marginTop: '20px' }}>
                The most important options to set the same as previous compilers
                are:
              </p>

              <UnorderedList>
                <ListItem>ADV</ListItem>
                <ListItem>AWO</ListItem>
                <ListItem>DYNAM</ListItem>
                <ListItem>
                  NUMPROC (other than changing from NUMPROC(MIG) to
                  NUMPROC(NOPFD))
                </ListItem>
                <ListItem>TRUNC</ListItem>
              </UnorderedList>

              <p style={{ lineHeight: '20px', marginTop: '20px' }}>
                You should also:
              </p>

              <OrderedList nested={false}>
                <ListItem>
                  Set up your source library control system compiler options for
                  the new compiler to be compatible with the old compiler(s).
                </ListItem>
                <ListItem>
                  Document all customizations and setups performed for future
                  reference.
                </ListItem>
              </OrderedList>

              <p style={{ lineHeight: '20px', marginTop: '20px' }}>Note:</p>

              <UnorderedList>
                <ListItem>
                  Do NOT combine COBOL compiler migrations with other
                  migrations. In particular, do not try to switch from the Db2
                  precompiler or the separate CICS translator to using the SQL
                  or CICS compiler options (integrated coprocessors) at the same
                  time as your COBOL compiler migration.
                </ListItem>
                <ListItem>
                  Do NOT migrate z/OS, Db2 or CICS at the same time as a COBOL
                  compiler migration. COBOL migration is a significant migration
                  on its own!
                </ListItem>
              </UnorderedList>

              <div>
                <Form
                  className="some-class"
                  onSubmit={function noRefCheck() {}}>
                  <div
                    style={{
                      alignItems: 'center',
                      display: 'flex',
                      flexWrap: 'wrap',
                      marginTop: '20px',
                      float: 'right',
                      paddingBottom: '48px',
                    }}>
                    <Button
                      className="some-class"
                      disabled={false}
                      kind="secondary"
                      tabIndex={0}
                      type="submit"
                      onClick={this.goBackButton}>
                      Go back
                    </Button>

                    <Button
                      style={{ marginLeft: '10px' }}
                      className="some-class"
                      disabled={false}
                      kind="primary"
                      tabIndex={0}
                      type="submit"
                      onClick={this.proceedButton}>
                      Proceed
                    </Button>
                  </div>
                </Form>
              </div>
            </section>
          </div>
        </div>
      </Content>
    );
  }
}
