import React from 'react';

import {
  Form,
  FormGroup,
  Button,
  RadioButtonGroup,
  RadioButton,
} from 'carbon-components-react';

import { Content } from 'carbon-components-react/lib/components/UIShell';

export class SelectMigrationTarget extends React.Component {
  proceedButton = () => {
    this.props.proceedButtonSelectMigrationTarget();
  };

  render() {
    return (
      <Content id="main-content">
        <div className="bx--grid">
          <div className="bx--row">
            <section className="bx--col-lg-10">
              <h2
                style={{
                  //fontWeight: '800',
                  margin: '20px 0',
                  fontSize: '24px',
                }}>
                1. Select a migration target
              </h2>
              <p style={{ lineHeight: '20px' }}>
                To start the migration process, select the target COBOL compiler
                version:
              </p>
              <br></br>

              <Form className="some-class" onSubmit={function noRefCheck() {}}>
                <FormGroup
                  className="some-class"
                  invalid={false}
                  //legendText="Radio Button heading"
                  message={false}
                  messageText="">
                  <RadioButtonGroup
                    labelPosition="right"
                    name="radio-button-group"
                    onChange={value => {
                      console.log('I am clicking the ratio button: ', value);

                      this.props.setOptionSelectMigrationTarget(value);
                    }}
                    orientation="vertical"
                    defaultSelected="IBM Enterprise COBOL for z/OS 6.3"
                    valueSelected={this.props.currentState.version}>
                    {/* <RadioButton
                      className="some-class"
                      id="radio-1"
                      labelText="IBM Enterprise COBOL for z/OS 5.2"
                      value="IBM Enterprise COBOL for z/OS 5.2"
                    /> */}
                    {/* <RadioButton
                      className="some-class"
                      id="radio-2"
                      labelText="IBM Enterprise COBOL for z/OS 6.1"
                      value="IBM Enterprise COBOL for z/OS 6.1"
                    /> */}
                    <RadioButton
                      className="some-class"
                      id="radio-3"
                      labelText="IBM Enterprise COBOL for z/OS 6.2"
                      value="IBM Enterprise COBOL for z/OS 6.2"
                    />
                    <RadioButton
                      className="some-class"
                      id="radio-4"
                      labelText="IBM Enterprise COBOL for z/OS 6.3"
                      value="IBM Enterprise COBOL for z/OS 6.3"
                    />
					<RadioButton
                      className="some-class"
                      id="radio-5"
                      labelText="IBM Enterprise COBOL for z/OS 6.4"
                      value="IBM Enterprise COBOL for z/OS 6.4"
                    />
                  </RadioButtonGroup>
                </FormGroup>

                {/* <div>
                  {this.props.currentState.version && (
                    <p>{this.props.currentState.version} ?</p>
                  )}
                </div>*/}

                <div
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexWrap: 'wrap',
                    marginTop: '20px',
                    float: 'right',
                    paddingBottom: '48px',
                  }}>
                  <Button
                    style={{ marginTop: '20px' }}
                    className="some-class"
                    disabled={false}
                    kind="primary"
                    tabIndex={0}
                    type="submit"
                    onClick={this.proceedButton}>
                    Proceed
                  </Button>
                </div>
              </Form>
            </section>
          </div>
        </div>
      </Content>
    );
  }
}
