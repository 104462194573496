import React from 'react';

import {
  Form,
  FormGroup,
  Button,
  RadioButtonGroup,
  RadioButton,
} from 'carbon-components-react';

import { Content } from 'carbon-components-react/lib/components/UIShell';

export class InstallNewCOBOL extends React.Component {
  proceedButton = () => {
    this.props.proceedButtonInstallNewCOBOL();
  };

  goBackButton = () => {
    this.props.backButtonInstallNewCOBOL();
  };

  render() {
    //const location = this.props.location;
    let version = '"Please select a version in the first step"';

    if (this.props.selectMigrationTarget.version) {
      version = this.props.selectMigrationTarget.version;
    }
    //const location = this.props.location;
    return (
      <Content id="main-content">
        <div className="bx--grid">
          <div className="bx--row">
            <section className="bx--col-lg-10">
              <h2
                style={{
                  //fontWeight: '800',
                  margin: '20px 0',
                  fontSize: '24px',
                }}>
                9. Install new COBOL compiler
              </h2>
              <p style={{ lineHeight: '20px' }}>
                Purchase{' '}
                <a
                  href="https://www.ibm.com/products/cobol-compiler-zos/pricing"
                  rel="noopener noreferrer"
                  target="_blank">
                  {version}
                </a>{' '}
                and install it and the latest PTFs on all development systems
                where COBOL compiles are performed.
              </p>

              <div>
                <Form
                  className="some-class"
                  onSubmit={function noRefCheck() {}}>
                  <div
                    style={{
                      alignItems: 'center',
                      display: 'flex',
                      flexWrap: 'wrap',
                      marginTop: '20px',
                      float: 'right',
                      paddingBottom: '48px',
                    }}>
                    <Button
                      className="some-class"
                      disabled={false}
                      kind="secondary"
                      tabIndex={0}
                      type="submit"
                      onClick={this.goBackButton}>
                      Go back
                    </Button>

                    <Button
                      style={{ marginLeft: '10px' }}
                      className="some-class"
                      disabled={false}
                      kind="primary"
                      tabIndex={0}
                      type="submit"
                      onClick={this.proceedButton}>
                      Proceed
                    </Button>
                  </div>
                </Form>
              </div>
            </section>
          </div>
        </div>
      </Content>
    );
  }
}
