import React from 'react';

import {
  Form,
  FormGroup,
  Button,
  RadioButtonGroup,
  RadioButton,
} from 'carbon-components-react';

import { Content } from 'carbon-components-react/lib/components/UIShell';

export class ConvertPDSLoad extends React.Component {
  proceedButton = () => {
    this.props.proceedButtonConvertPDSLoad();
  };

  goBackButton = () => {
    this.props.backButtonConvertPDSLoad();
  };

  render() {
    //const location = this.props.location;
    return (
      <Content id="main-content">
        <div className="bx--grid">
          <div className="bx--row">
            <section className="bx--col-lg-10">
              <h2
                style={{
                  //fontWeight: '800',
                  margin: '20px 0',
                  fontSize: '24px',
                }}>
                2. Convert PDS load library
              </h2>
              <p style={{ lineHeight: '20px' }}>
              Are your COBOL executables (load modules or program objects) in PDS (not PDSE) data sets? (These data sets are often 
              called 'load libraries'.)
              </p>

              <div>
                <Form
                  className="some-class"
                  onSubmit={function noRefCheck() {}}>
                  <div style={{ marginTop: '20px' }}>
                    <FormGroup
                      className="some-class"
                      invalid={false}
                      //legendText="Radio Button heading"
                      message={false}
                      messageText="">
                      <RadioButtonGroup
                        labelPosition="right"
                        name="radio-button-group"
                        valueSelected={this.props.currentState.yesOrNo}
                        onChange={value => {
                          this.props.ConvertPDSLoadButton(value);
                          console.log('choose value is:', value);
                        }}
                        orientation="horizontal">
                        <RadioButton
                          className="some-class"
                          id="radio-1"
                          labelText="No"
                          value="No"
                        />
                        <RadioButton
                          className="some-class"
                          id="radio-2"
                          labelText="Yes"
                          value="Yes"
                        />
                      </RadioButtonGroup>
                    </FormGroup>
                  </div>

                  <div>
                    {this.props.currentState.yesOrNo === 'Yes' && (
                      <div>
                        <p style={{ lineHeight: '20px', marginTop: '20px' }}>
                          IBM Enterprise COBOL for z/OS 5 and later compilers
                          require executables to be in PDSE data sets (data set type must be LIBRARY). To use
                          COBOL 5 and later versions,{' '}
                          <a
                            href="https://www.ibm.com/support/pages/node/594759"
                            rel="noopener noreferrer"
                            target="_blank">
                            migrate them to PDSE load libraries
                          </a>
                          .
                        </p>

                        <p style={{ lineHeight: '20px', marginTop: '20px' }}>
                          Have you migrated all your PDS load libaries to PDSE?
                        </p>
                      </div>
                    )}
                  </div>

                  {this.props.currentState.yesOrNo === 'Yes' && (
                    <div style={{ marginTop: '20px' }}>
                      <FormGroup
                        className="some-class"
                        invalid={false}
                        //legendText="Radio Button heading"
                        message={false}
                        messageText="">
                        <RadioButtonGroup
                          labelPosition="right"
                          name="radio-button-group2"
                          valueSelected={this.props.currentState.yesOrNo2}
                          onChange={value => {
                            this.props.ConvertPDSLoadButton2(value);
                            //console.log('choose value is:', value);
                          }}
                          orientation="horizontal">
                          <RadioButton
                            className="some-class"
                            id="radio-3"
                            labelText="No"
                            value="No"
                          />
                          <RadioButton
                            className="some-class"
                            id="radio-4"
                            labelText="Yes"
                            value="Yes"
                          />
                        </RadioButtonGroup>
                      </FormGroup>
                    </div>
                  )}

                  <div
                    style={{
                      alignItems: 'center',
                      display: 'flex',
                      flexWrap: 'wrap',
                      marginTop: '20px',
                      float: 'right',
                      paddingBottom: '48px',
                    }}>
                    <Button
                      className="some-class"
                      disabled={false}
                      kind="secondary"
                      tabIndex={0}
                      type="submit"
                      onClick={this.goBackButton}>
                      Go back
                    </Button>

                    <Button
                      style={{ marginLeft: '10px' }}
                      className="some-class"
                      disabled={false}
                      kind="primary"
                      tabIndex={0}
                      type="submit"
                      onClick={this.proceedButton}>
                      Proceed
                    </Button>
                  </div>
                </Form>
              </div>
            </section>
          </div>
        </div>
      </Content>
    );
  }
}
