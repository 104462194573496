import React from 'react';

import {
  SideNav,
  SideNavItems,
  SideNavLink,
} from 'carbon-components-react/lib/components/UIShell';

import { Loading } from 'carbon-components-react';

import { SelectMigrationTarget } from './MainContents/SelectMigrationTarget';
import { ConvertPDSLoad } from './MainContents/ConvertPDSLoad';
import { CheckCOBOLdependencies } from './MainContents/CheckCOBOLdependencies';
import { CompleteLanguage } from './MainContents/CompleteLanguage';
import { UpgradeSoftware } from './MainContents/UpgradeSoftware';
import { InstallAllPrerequisite } from './MainContents/InstallAllPrerequisite';
import { VerifyRegionSize } from './MainContents/VerifyRegionSize';
import { BackupOldCOBOL } from './MainContents/BackupOldCOBOL';
import { InstallNewCOBOL } from './MainContents/InstallNewCOBOL';
import { SetDefaultCompiler } from './MainContents/SetDefaultCompiler';
import { UpdateCOBOLSource } from './MainContents/UpdateCOBOLSource';
import { RegressionTesting } from './MainContents/RegressionTesting';
import { VerifyMigration } from './MainContents/VerifyMigration';
import { PostMigration } from './MainContents/PostMigration';
import { CompletePage } from './MainContents/CompletePage';

import { RadioButton20 } from '@carbon/icons-react';
//import { CheckmarkOutline20 } from '@carbon/icons-react';
import CheckmarkOutline20 from '@carbon/icons-react/lib/checkmark--outline/20';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as actions from '../../actions';

class AssistantPage extends React.Component {
  constructor(props) {
    super(props);

    this.child = React.createRef();

    this.state = {
      selectMigrationTarget: {
        complete: false,
        version: 'IBM Enterprise COBOL for z/OS 6.3',
      },
      convertPDSLoad: { complete: false, yesOrNo: '', yesOrNo2: '' },
      checkCOBOLdependencies: { complete: false, yesOrNo: '', option: '' },
      completeLanguage: {
        complete: false,
        option: [false, false, false, false, false, false],
      },
      upgradeSoftware: { complete: false, yesOrNo: '' },
      installAllPrerequisite: { complete: false, yesOrNo: '' },
      verifyRegionSize: { complete: false, yesOrNo: '' },
      backupOldCOBOL: { complete: false, yesOrNo: '' },
      installNewCOBOL: { complete: false },
      setDefaultCompiler: { complete: false },
      updateCOBOLSource: { complete: false, yesOrNo: '', yesOrNo2: '' },
      regressionTesting: {
        complete: false,
        yesOrNo: '',
        yesOrNo2: false,
        modealOpen: false,
      },
      verifyMigration: { complete: false, yesOrNo: '' },
      postMigration: { complete: false, yesOrNo: '' },
      completePage: {
        showModal: false,
        score: '',
        comment: '',
        isValid: true,
        showError: false,
        showError1: false,
        errorMessage: '',
      },
      currentStep: 0,
    };
  }

  //----------------------------------------------------------------
  //Proceed buttons
  //----------------------------------------------------------------
  proceedButtonSelectMigrationTarget = async () => {
    this.setState({
      currentStep: 1,
      selectMigrationTarget: {
        complete: this.state.selectMigrationTarget.version ? true : false,
        version: this.state.selectMigrationTarget.version,
      },
    });

    this.updateDatabase();
  };

  setOptionSelectMigrationTarget = async value => {
    await this.setState({
      selectMigrationTarget: {
        complete: this.state.selectMigrationTarget.complete,
        version: value,
      },
    });

    this.updateDatabase();
  };

  proceedButtonConvertPDSLoad = async () => {
    let complete = false;
    if (
      (this.state.convertPDSLoad.yesOrNo &&
        this.state.convertPDSLoad.yesOrNo === 'No') ||
      (this.state.convertPDSLoad.yesOrNo && this.state.convertPDSLoad.yesOrNo2)
    )
      complete = true;

    await this.setState({
      currentStep: 2,
      convertPDSLoad: {
        complete: complete,
        yesOrNo: this.state.convertPDSLoad.yesOrNo,
        yesOrNo2: this.state.convertPDSLoad.yesOrNo2,
      },
    });

    this.updateDatabase();
  };

  proceedButtonCheckCOBOLdependencies = async () => {
    let complete = false;
    if (
      this.state.checkCOBOLdependencies.yesOrNo &&
      (this.state.checkCOBOLdependencies.yesOrNo === 'No' ||
        (this.state.checkCOBOLdependencies.yesOrNo === 'Yes' &&
          this.state.checkCOBOLdependencies.option))
    ) {
      complete = true;
    }

    await this.setState({
      currentStep: 3,
      checkCOBOLdependencies: {
        complete: complete,
        yesOrNo: this.state.checkCOBOLdependencies.yesOrNo,
        option: this.state.checkCOBOLdependencies.option,
      },
    });

    this.updateDatabase();
  };

  proceedButtonCompleteLanguage = async () => {
    //console.log('calling proceedButtonCompleteLanguage');
    let val = this.state.completeLanguage.option.some(item => item === true);

    await this.setState({
      currentStep: 4,
      completeLanguage: {
        complete: val,
        option: this.state.completeLanguage.option,
      },
    });

    this.updateDatabase();
  };

  CompleteLanguageButton = async (index, value) => {
    console.log('I am calling complete language button function:', index);

    let arr = [...this.state.completeLanguage.option];
    arr[index] = !arr[index];

    await this.setState({
      completeLanguage: {
        complete: this.state.completeLanguage.complete,
        option: arr,
      },
    });

    this.updateDatabase();
  };

  proceedButtonUpgradeSoftware = async () => {
    await this.setState({
      currentStep: 5,
      upgradeSoftware: {
        complete: this.state.upgradeSoftware.yesOrNo ? true : false,
        yesOrNo: this.state.upgradeSoftware.yesOrNo,
      },
    });

    this.updateDatabase();
  };

  proceedButtonInstallAllPrerequisite = async () => {
    await this.setState({
      currentStep: 6,
      installAllPrerequisite: {
        complete: this.state.installAllPrerequisite.yesOrNo ? true : false,
        yesOrNo: this.state.installAllPrerequisite.yesOrNo,
      },
    });

    this.updateDatabase();
  };

  proceedButtonVerifyRegionSize = async () => {
    this.setState({
      currentStep: 7,
      verifyRegionSize: {
        complete: this.state.verifyRegionSize.yesOrNo ? true : false,
        yesOrNo: this.state.verifyRegionSize.yesOrNo,
      },
    });

    this.updateDatabase();
  };

  proceedButtonBackupOldCOBOL = async () => {
    this.setState({
      currentStep: 8,
      backupOldCOBOL: {
        complete: this.state.backupOldCOBOL.yesOrNo ? true : false,
        yesOrNo: this.state.backupOldCOBOL.yesOrNo,
      },
    });

    this.updateDatabase();
  };

  proceedButtonInstallNewCOBOL = async () => {
    this.setState({
      currentStep: 9,
      installNewCOBOL: {
        complete: true,
        //yesOrNo: this.state.installNewCOBOL.yesOrNo,
      },
    });

    this.updateDatabase();
  };

  proceedButtonSetDefaultCompiler = async () => {
    this.setState({
      currentStep: 10,
      setDefaultCompiler: {
        complete: true,
      },
    });

    this.updateDatabase();
  };

  proceedButtonUpdateCOBOLSource = async () => {
    await this.setState({
      currentStep: 11,
      updateCOBOLSource: {
        complete: this.state.updateCOBOLSource.yesOrNo ? true : false,
        yesOrNo: this.state.updateCOBOLSource.yesOrNo,
        yesOrNo2: this.state.updateCOBOLSource.yesOrNo2,
      },
    });

    this.updateDatabase();
  };

  proceedButtonRegressionTesting = async () => {
    await this.setState({
      currentStep: 12,
      regressionTesting: {
        complete: this.state.regressionTesting.yesOrNo ? true : false,
        yesOrNo: this.state.regressionTesting.yesOrNo,
        yesOrNo2: this.state.regressionTesting.yesOrNo2,
        modealOpen: this.state.regressionTesting.modealOpen,
      },
    });

    this.updateDatabase();
  };

  proceedButtonVerifyMigration = async () => {
    await this.setState({
      currentStep: 13,
      verifyMigration: {
        complete: this.state.verifyMigration.yesOrNo ? true : false,
        yesOrNo: this.state.verifyMigration.yesOrNo,
      },
    });

    this.updateDatabase();
  };

  proceedButtonPostMigration = async () => {
    await this.setState({
      currentStep: 14,
      postMigration: {
        complete: this.state.postMigration.yesOrNo ? true : false,
        yesOrNo: this.state.postMigration.yesOrNo,
      },
      completePage: {
        showModal: true,
        score: '',
        comment: '',
        isValid: true,
        showError: false,
        showError1: false,
        errorMessage: '',
      },
    });
    this.updateDatabase();
  };

  proceedButtonCompletePage = () => {
    //this.props.history.push('/summary');

    this.props.history.push({
      pathname: '/summary',
      state: {
        convertPDSLoad: this.state.convertPDSLoad,
        checkCOBOLdependencies: this.state.checkCOBOLdependencies,
        completeLanguage: this.state.completeLanguage,
        upgradeSoftware: this.state.upgradeSoftware,
        installAllPrerequisite: this.state.installAllPrerequisite,
        verifyRegionSize: this.state.verifyRegionSize,
        backupOldCOBOL: this.state.backupOldCOBOL,
        installNewCOBOL: this.state.installNewCOBOL,
        setDefaultCompiler: this.state.setDefaultCompiler,
        updateCOBOLSource: this.state.updateCOBOLSource,
        regressionTesting: this.state.regressionTesting,
        verifyMigration: this.state.verifyMigration,
        postMigration: this.state.postMigration,
      },
    });
  };
  //----------------------------------------------------------------
  //Proceed buttons
  //----------------------------------------------------------------

  //---------------------------------------
  //---------------------------------------
  //---------------------------------------
  //back buttons

  backButtonConvertPDSLoad = () => {
    this.setState({
      currentStep: 0,
      convertPDSLoad: {
        complete: this.state.convertPDSLoad.complete,
        yesOrNo: this.state.convertPDSLoad.yesOrNo,
        yesOrNo2: this.state.convertPDSLoad.yesOrNo2,
      },
    });
  };

  backButtonCheckCOBOLdependencies = () => {
    this.setState({
      currentStep: 1,
      checkCOBOLdependencies: {
        complete: this.state.checkCOBOLdependencies.complete,
        yesOrNo: this.state.checkCOBOLdependencies.yesOrNo,
        option: this.state.checkCOBOLdependencies.option,
      },
    });
  };

  backButtonCompleteLanguage = () => {
    this.setState({
      currentStep: 2,
      completeLanguage: {
        complete: this.state.completeLanguage.complete,
        option: this.state.completeLanguage.option,
      },
    });
  };

  backButtonUpgradeSoftware = () => {
    this.setState({
      currentStep: 3,
      upgradeSoftware: {
        complete: this.state.upgradeSoftware.complete,
        yesOrNo: this.state.upgradeSoftware.yesOrNo,
      },
    });
  };

  backButtonInstallAllPrerequisite = () => {
    this.setState({
      currentStep: 4,
      installAllPrerequisite: {
        complete: this.state.installAllPrerequisite.complete,
        yesOrNo: this.state.installAllPrerequisite.yesOrNo,
      },
    });
  };

  backButtonVerifyRegionSize = () => {
    this.setState({
      currentStep: 5,
      verifyRegionSize: {
        complete: this.state.verifyRegionSize.complete,
        yesOrNo: this.state.verifyRegionSize.yesOrNo,
      },
    });
  };

  backButtonBackupOldCOBOL = () => {
    this.setState({
      currentStep: 6,
      backupOldCOBOL: {
        complete: this.state.backupOldCOBOL.comlete,
        yesOrNo: this.state.backupOldCOBOL.yesOrNo,
      },
    });
  };

  backButtonInstallNewCOBOL = () => {
    this.setState({
      currentStep: 7,
      installNewCOBOL: {
        complete: this.state.installNewCOBOL.complete,
        //yesOrNo: this.state.installNewCOBOL.yesOrNo,
      },
    });
  };

  backButtonSetDefaultCompiler = () => {
    this.setState({
      currentStep: 8,
      setDefaultCompiler: {
        complete: this.state.setDefaultCompiler.complete,
      },
    });
  };

  backButtonUpdateCOBOLSource = () => {
    this.setState({
      currentStep: 9,
      updateCOBOLSource: {
        complete: this.state.updateCOBOLSource.complete,
        yesOrNo: this.state.updateCOBOLSource.yesOrNo,
        yesOrNo2: this.state.updateCOBOLSource.yesOrNo2,
      },
    });
  };

  backButtonRegressionTesting = () => {
    this.setState({
      currentStep: 10,
      regressionTesting: {
        complete: this.state.regressionTesting.complete,
        yesOrNo: this.state.regressionTesting.yesOrNo,
        yesOrNo2: this.state.regressionTesting.yesOrNo2,
        modealOpen: this.state.regressionTesting.modealOpen,
      },
    });
  };

  backButtonVerifyMigration = () => {
    this.setState({
      currentStep: 11,
      verifyMigration: {
        complete: this.state.verifyMigration.complete,
        yesOrNo: this.state.verifyMigration.yesOrNo,
      },
    });
  };

  backButtonPostMigration = () => {
    this.setState({
      currentStep: 12,
      postMigration: {
        complete: this.state.postMigration.complete,
        yesOrNo: this.state.postMigration.yesOrNo,
      },
    });
  };
  //back buttons
  //---------------------------------------
  //---------------------------------------
  //---------------------------------------

  ConvertPDSLoadButton = async val => {
    await this.setState({
      convertPDSLoad: {
        complete: this.state.convertPDSLoad.complete,
        yesOrNo: val,
        yesOrNo2: this.state.convertPDSLoad.yesOrNo2,
      },
    });

    this.updateDatabase();
  };

  ConvertPDSLoadButton2 = async val => {
    await this.setState({
      convertPDSLoad: {
        complete: this.state.convertPDSLoad.complete,
        yesOrNo: this.state.convertPDSLoad.yesOrNo,
        yesOrNo2: val,
      },
    });

    this.updateDatabase();
  };

  CheckCOBOLdependenciesButton = async val => {
    await this.setState({
      checkCOBOLdependencies: {
        complete: this.state.checkCOBOLdependencies.complete,
        yesOrNo: val,
        option: null,
      },
    });

    this.updateDatabase();
  };

  CheckCOBOLdependenciesOption = async val => {
    await this.setState({
      checkCOBOLdependencies: {
        complete: this.state.checkCOBOLdependencies.complete,
        yesOrNo: this.state.checkCOBOLdependencies.yesOrNo,
        option: val,
      },
    });

    this.updateDatabase();
  };

  UpgradeSoftwareButton = async val => {
    await this.setState({
      upgradeSoftware: {
        complete: this.state.upgradeSoftware.complete,
        yesOrNo: val,
      },
    });

    this.updateDatabase();
  };

  InstallAllPrerequisiteButton = async val => {
    //console.log('InstallAllPrerequisiteButton select value:', val);

    await this.setState({
      installAllPrerequisite: {
        complete: this.state.installAllPrerequisite.complete,
        yesOrNo: val,
      },
    });

    this.updateDatabase();
  };

  VerifyRegionSizeButton = async val => {
    await this.setState({
      verifyRegionSize: {
        complete: this.state.verifyRegionSize.complete,
        yesOrNo: val,
      },
    });

    this.updateDatabase();
  };

  BackupOldCOBOLButton = async val => {
    await this.setState({
      backupOldCOBOL: {
        complete: this.state.backupOldCOBOL.complete,
        yesOrNo: val,
      },
    });
  };

  InstallNewCOBOLButton = async val => {
    await this.setState({
      installNewCOBOL: {
        complete: this.state.installNewCOBOL.complete,
        //yesOrNo: val,
      },
    });
  };

  UpdateCOBOLSourceButton = async val => {
    await this.setState({
      updateCOBOLSource: {
        complete: this.state.updateCOBOLSource.complete,
        yesOrNo: val,
        yesOrNo2: this.state.updateCOBOLSource.yesOrNo2,
      },
    });
  };

  UpdateCOBOLSourceButton2 = async val => {
    await this.setState({
      updateCOBOLSource: {
        complete: this.state.updateCOBOLSource.complete,
        yesOrNo: this.state.updateCOBOLSource.yesOrNo,
        yesOrNo2: val,
      },
    });
  };

  RegressionTestingButton = async val => {
    await this.setState({
      regressionTesting: {
        complete: this.state.regressionTesting.complete,
        yesOrNo: val,
        yesOrNo2: false,
        modealOpen: this.state.regressionTesting.modealOpen,
      },
    });
  };

  RegressionTestingButton2 = async val => {
    await this.setState({
      regressionTesting: {
        complete: this.state.regressionTesting.complete,
        yesOrNo: this.state.regressionTesting.yesOrNo,
        yesOrNo2: val,
        modealOpen: val === 'Yes' ? true : false,
      },
    });
  };

  VerifyMigrationButton = async val => {
    await this.setState({
      verifyMigration: {
        complete: this.state.verifyMigration.complete,
        yesOrNo: val,
      },
    });
  };

  PostMigrationButton = async val => {
    await this.setState({
      postMigration: { complete: this.state.postMigration, yesOrNo: val },
    });
  };

  regtestCloseModal = () => {
    this.setState({
      regressionTesting: {
        complete: this.state.regressionTesting.complete,
        yesOrNo: this.state.regressionTesting.yesOrNo,
        yesOrNo2: this.state.regressionTesting.yesOrNo2,
        modealOpen: false,
      },
    });
  };

  regtestOpenModal = () => {
    this.setState({
      regressionTesting: {
        complete: this.state.regressionTesting.complete,
        yesOrNo: this.state.regressionTesting.yesOrNo,
        yesOrNo2: this.state.regressionTesting.yesOrNo2,
        modealOpen: true,
      },
    });
  };

  completeSVG = () => {
    return (
      <CheckmarkOutline20
        role="img"
        className="completeSVG"
        id="completeSVG1"
      />
    );
  };

  updateDatabase = () => {
    console.log(
      'update selectMigrationTarget:',
      this.state.selectMigrationTarget
    );
    actions
      .updateAvailableDataset([
        this.props.id,
        this.state.selectMigrationTarget,
        this.state.convertPDSLoad,
        this.state.checkCOBOLdependencies,
        this.state.completeLanguage,
        this.state.upgradeSoftware,
        this.state.installAllPrerequisite,
        this.state.verifyRegionSize,
        this.state.backupOldCOBOL,
        this.state.installNewCOBOL,
        this.state.setDefaultCompiler,
        this.state.updateCOBOLSource,
        this.state.regressionTesting,
        this.state.verifyMigration,
        this.state.postMigration,
        this.state.currentStep,
      ])
      .then(
        data => {
          console.log('successfully update the database');
        },
        err => {
          console.log('fail to update the database');
        }
      );
  };

  //------------- Feeback handlers ------------------

  /*
        showModal: false,
        score1: '',
        score2: '',
        comment: '',
        isValid: true,
        showError: false,
        showSuccess: false,
        showError1: false,
        showError2: false,
        errorMessage: '',
        successMessage: '',

  */

  submitFeedback = () => {
    if (this.state.completePage.score === '') {
      console.log('I am in this.state.score1 is empty');
      this.setState({
        completePage: {
          showModal: true,
          score: '',
          comment: '',
          isValid: true,
          showError: false,
          showError1: true,
          errorMessage: '',
        },
      });
    } else if (!this.state.completePage.isValid) {
      console.log('I am in this.state.isValid not');
      this.setState({
        completePage: {
          showModal: true,
          showError: true,
          errorMessage: 'Feedback message must not exceed 500 characters',
          score: this.state.completePage.score,
          comment: this.state.completePage.comment,
          isValid: this.state.completePage.isValid,
          showError1: this.state.completePage.showError1,
        },
      });
    } else {
      //console.log('all the inputs are valid!');

      actions
        .updateNPSFeedback([
          this.props.id,
          this.state.completePage.score,
          this.state.completePage.comment,
        ])
        .then(
          data => {
            console.log('successfully update the database', data);

            this.setState({
              completePage: {
                showModal: false,
                showError: this.state.completePage.showError,
                errorMessage: this.state.completePage.errorMessage,
                score: this.state.completePage.score,
                comment: this.state.completePage.comment,
                isValid: this.state.completePage.isValid,
                showError1: this.state.completePage.showError1,
              },
            });
          },
          err => {
            console.log('fail to update the database:', err);

            this.setState({
              completePage: {
                showModal: false,
                showError: this.state.completePage.showError,
                errorMessage: this.state.completePage.errorMessage,
                score: this.state.completePage.score,
                comment: this.state.completePage.comment,
                isValid: this.state.completePage.isValid,
                showError1: this.state.completePage.showError1,
              },
            });
          }
        );
    }
  };

  completeCloseModal = () => {
    this.setState({
      completePage: {
        showModal: false,
        showError: this.state.completePage.showError,
        errorMessage: this.state.completePage.errorMessage,
        score: this.state.completePage.score,
        comment: this.state.completePage.comment,
        isValid: this.state.completePage.isValid,
        showError1: this.state.completePage.showError1,
      },
    });
  };

  selectNPSScore = value => {
    this.setState({
      completePage: {
        showModal: true,
        showError: this.state.completePage.showError,
        errorMessage: this.state.completePage.errorMessage,
        score: value,
        comment: this.state.completePage.comment,
        isValid: this.state.completePage.isValid,
        showError1: false,
      },
    });
  };

  updateNPScomment = (value, valid) => {
    this.setState({
      completePage: {
        showModal: true,
        showError: this.state.completePage.showError,
        errorMessage: this.state.completePage.errorMessage,
        score: this.state.completePage.score,
        comment: value,
        isValid: valid,
        showError1: this.state.completePage.showError1,
      },
    });
  };

  closeError = () => {
    this.setState({
      completePage: {
        showModal: true,
        showError: false,
        errorMessage: this.state.completePage.errorMessage,
        score: this.state.completePage.score,
        comment: this.state.completePage.comment,
        isValid: this.state.completePage.isValid,
        showError1: this.state.completePage.showError1,
      },
    });
  };
  //--------------------------------------------------------------------------

  async componentDidMount() {
    let currentPage = null;
    if (this.props.location.state && this.props.location.state.currentStep) {
      console.log(
        'location state has values:',
        this.props.location.state.currentStep
      );
      currentPage = this.props.location.state.currentStep;
      //this.setState({ currentStep: this.props.location.state.currentStep });
      window.history.replaceState(null, '');
    }

    await this.props.dispatch(actions.checkAuth());

    if (this.props.id) {
      console.log('I am login:', this.props.id);

      await this.props.fetchDataset(this.props.id);

      const datasets =
        typeof this.props.datasets === 'undefined' ? [] : this.props.datasets;

      if (datasets) {
        if (currentPage === null) currentPage = datasets.currentStep;

        this.setState({
          selectMigrationTarget: {
            complete: datasets.selectMigrationTarget.complete,
            version: datasets.selectMigrationTarget.version,
          },
          convertPDSLoad: {
            complete: datasets.convertPDSLoad.complete ? true : false,
            yesOrNo: datasets.convertPDSLoad.yesOrNo,
            yesOrNo2: datasets.convertPDSLoad.yesOrNo2,
          },
          checkCOBOLdependencies: {
            complete: datasets.checkCOBOLdependencies.complete ? true : false,
            yesOrNo: datasets.checkCOBOLdependencies.yesOrNo,
            option: datasets.checkCOBOLdependencies.option,
          },
          completeLanguage: {
            complete: datasets.completeLanguage.complete ? true : false,
            option: datasets.completeLanguage.option,
          },
          upgradeSoftware: {
            complete: datasets.upgradeSoftware.complete ? true : false,
            yesOrNo: datasets.upgradeSoftware.yesOrNo,
          },
          installAllPrerequisite: {
            complete: datasets.installAllPrerequisite.complete ? true : false,
            yesOrNo: datasets.installAllPrerequisite.yesOrNo,
          },
          verifyRegionSize: {
            complete: datasets.verifyRegionSize.complete ? true : false,
            yesOrNo: datasets.verifyRegionSize.yesOrNo,
          },
          backupOldCOBOL: {
            complete: datasets.backupOldCOBOL.complete ? true : false,
            yesOrNo: datasets.backupOldCOBOL.yesOrNo,
          },
          installNewCOBOL: {
            complete: datasets.installNewCOBOL.complete ? true : false,
            //yesOrNo: datasets.installNewCOBOL.yesOrNo,
          },
          setDefaultCompiler: {
            complete: datasets.setDefaultCompiler.complete ? true : false,
          },
          updateCOBOLSource: {
            complete: datasets.updateCOBOLSource.complete ? true : false,
            yesOrNo: datasets.updateCOBOLSource.yesOrNo,
            yesOrNo2: datasets.updateCOBOLSource.yesOrNo2,
          },
          regressionTesting: {
            complete: datasets.regressionTesting.comlete ? true : false,
            yesOrNo: datasets.regressionTesting.yesOrNo,
            yesOrNo2: datasets.regressionTesting.yesOrNo2,
            modealOpen: datasets.regressionTesting.modealOpen,
          },
          verifyMigration: {
            complete: datasets.verifyMigration.complete ? true : false,
            yesOrNo: datasets.verifyMigration.yesOrNo,
          },
          postMigration: {
            complete: datasets.postMigration.complete ? true : false,
            yesOrNo: datasets.postMigration.yesOrNo,
          },
          currentStep: currentPage,
        });

        //console.log('after updated current step:', this.state.currentStep);
      }
    } else {
      //this.props.history.push('/');
      window.location.href = '/appid/login';
    }
  }

  render() {
    const isAuthenitcated = true;
    const agreement = true;
    const maintenance = false;

    let content;
    if (this.state.currentStep === 0) {
      content = (
        <SelectMigrationTarget
          proceedButtonSelectMigrationTarget={
            this.proceedButtonSelectMigrationTarget
          }
          currentState={this.state.selectMigrationTarget}
          setOptionSelectMigrationTarget={this.setOptionSelectMigrationTarget}
        />
      );
    } else if (this.state.currentStep === 1) {
      content = (
        <ConvertPDSLoad
          proceedButtonConvertPDSLoad={this.proceedButtonConvertPDSLoad}
          backButtonConvertPDSLoad={this.backButtonConvertPDSLoad}
          ConvertPDSLoadButton={this.ConvertPDSLoadButton}
          ConvertPDSLoadButton2={this.ConvertPDSLoadButton2}
          currentState={this.state.convertPDSLoad}
        />
      );
    } else if (this.state.currentStep === 2) {
      content = (
        <CheckCOBOLdependencies
          proceedButtonCheckCOBOLdependencies={
            this.proceedButtonCheckCOBOLdependencies
          }
          backButtonCheckCOBOLdependencies={
            this.backButtonCheckCOBOLdependencies
          }
          CheckCOBOLdependenciesButton={this.CheckCOBOLdependenciesButton}
          CheckCOBOLdependenciesOption={this.CheckCOBOLdependenciesOption}
          currentState={this.state.checkCOBOLdependencies}
        />
      );
    } else if (this.state.currentStep === 3) {
      content = (
        <CompleteLanguage
          proceedButtonCompleteLanguage={this.proceedButtonCompleteLanguage}
          backButtonCompleteLanguage={this.backButtonCompleteLanguage}
          CompleteLanguageButton={this.CompleteLanguageButton}
          currentState={this.state.completeLanguage}
        />
      );
    } else if (this.state.currentStep === 4) {
      content = (
        <UpgradeSoftware
          proceedButtonUpgradeSoftware={this.proceedButtonUpgradeSoftware}
          backButtonUpgradeSoftware={this.backButtonUpgradeSoftware}
          UpgradeSoftwareButton={this.UpgradeSoftwareButton}
          currentState={this.state.upgradeSoftware}
          selectMigrationTarget={this.state.selectMigrationTarget}
        />
      );
    } else if (this.state.currentStep === 5) {
      content = (
        <InstallAllPrerequisite
          proceedButtonInstallAllPrerequisite={
            this.proceedButtonInstallAllPrerequisite
          }
          backButtonInstallAllPrerequisite={
            this.backButtonInstallAllPrerequisite
          }
          InstallAllPrerequisiteButton={this.InstallAllPrerequisiteButton}
          currentState={this.state.installAllPrerequisite}
          selectMigrationTarget={this.state.selectMigrationTarget}
        />
      );
    } else if (this.state.currentStep === 6) {
      content = (
        <VerifyRegionSize
          proceedButtonVerifyRegionSize={this.proceedButtonVerifyRegionSize}
          backButtonVerifyRegionSize={this.backButtonVerifyRegionSize}
          VerifyRegionSizeButton={this.VerifyRegionSizeButton}
          currentState={this.state.verifyRegionSize}
          selectMigrationTarget={this.state.selectMigrationTarget}
        />
      );
    } else if (this.state.currentStep === 7) {
      content = (
        <BackupOldCOBOL
          proceedButtonBackupOldCOBOL={this.proceedButtonBackupOldCOBOL}
          backButtonBackupOldCOBOL={this.backButtonBackupOldCOBOL}
          BackupOldCOBOLButton={this.BackupOldCOBOLButton}
          currentState={this.state.backupOldCOBOL}
        />
      );
    } else if (this.state.currentStep === 8) {
      content = (
        <InstallNewCOBOL
          proceedButtonInstallNewCOBOL={this.proceedButtonInstallNewCOBOL}
          backButtonInstallNewCOBOL={this.backButtonInstallNewCOBOL}
          InstallNewCOBOLButton={this.InstallNewCOBOLButton}
          currentState={this.state.installNewCOBOL}
          selectMigrationTarget={this.state.selectMigrationTarget}
        />
      );
    } else if (this.state.currentStep === 9) {
      content = (
        <SetDefaultCompiler
          proceedButtonSetDefaultCompiler={this.proceedButtonSetDefaultCompiler}
          backButtonSetDefaultCompiler={this.backButtonSetDefaultCompiler}
          currentState={this.state.setDefaultCompiler}
          selectMigrationTarget={this.state.selectMigrationTarget}
        />
      );
    } else if (this.state.currentStep === 10) {
      content = (
        <UpdateCOBOLSource
          proceedButtonUpdateCOBOLSource={this.proceedButtonUpdateCOBOLSource}
          backButtonUpdateCOBOLSource={this.backButtonUpdateCOBOLSource}
          UpdateCOBOLSourceButton={this.UpdateCOBOLSourceButton}
          UpdateCOBOLSourceButton2={this.UpdateCOBOLSourceButton2}
          currentState={this.state.updateCOBOLSource}
          selectMigrationTarget={this.state.selectMigrationTarget}
        />
      );
    } else if (this.state.currentStep === 11) {
      content = (
        <RegressionTesting
          proceedButtonRegressionTesting={this.proceedButtonRegressionTesting}
          backButtonRegressionTesting={this.backButtonRegressionTesting}
          RegressionTestingButton={this.RegressionTestingButton}
          RegressionTestingButton2={this.RegressionTestingButton2}
          closeModal={this.regtestCloseModal}
          openModal={this.regtestOpenModal}
          currentState={this.state.regressionTesting}
          selectMigrationTarget={this.state.selectMigrationTarget}
        />
      );
    } else if (this.state.currentStep === 12) {
      content = (
        <VerifyMigration
          proceedButtonVerifyMigration={this.proceedButtonVerifyMigration}
          backButtonVerifyMigration={this.backButtonVerifyMigration}
          VerifyMigrationButton={this.VerifyMigrationButton}
          currentState={this.state.verifyMigration}
        />
      );
    } else if (this.state.currentStep === 13) {
      content = (
        <PostMigration
          proceedButtonPostMigration={this.proceedButtonPostMigration}
          backButtonPostMigration={this.backButtonPostMigration}
          PostMigrationButton={this.PostMigrationButton}
          currentState={this.state.postMigration}
          selectMigrationTarget={this.state.selectMigrationTarget}
        />
      );
    } else if (this.state.currentStep === 14) {
      content = (
        <CompletePage
          proceedButtonCompletePage={this.proceedButtonCompletePage}
          currentState={this.state.completePage}
          submitFeedback={this.submitFeedback}
          closeModal={this.completeCloseModal}
          selectNPSScore={this.selectNPSScore}
          updateNPScomment={this.updateNPScomment}
          closeError={this.closeError}
        />
      );
    }

    //debugger;
    if (isAuthenitcated && agreement && !maintenance) {
      return (
        <div className="container">
          <>
            <SideNav
              isFixedNav
              expanded={true}
              isChildOfHeader={false}
              aria-label="Side navigation">
              <SideNavItems>
                <SideNavLink
                  renderIcon={
                    this.state.selectMigrationTarget.complete === true
                      ? this.completeSVG
                      : RadioButton20
                  }
                  href="javascript:void(0)"
                  onClick={() => {
                    this.setState({ currentStep: 0 });
                  }}>
                  Select migration target
                </SideNavLink>
                <SideNavLink
                  renderIcon={
                    this.state.convertPDSLoad.complete === true
                      ? this.completeSVG
                      : RadioButton20
                  }
                  href="javascript:void(0)"
                  onClick={() => {
                    this.setState({ currentStep: 1 });
                  }}>
                  Convert PDS load libraries
                </SideNavLink>
                <SideNavLink
                  renderIcon={
                    this.state.checkCOBOLdependencies.complete === true
                      ? this.completeSVG
                      : RadioButton20
                  }
                  href="javascript:void(0)"
                  onClick={() => {
                    this.setState({ currentStep: 2 });
                  }}>
                  Check COBOL dependencies
                </SideNavLink>
                <SideNavLink
                  renderIcon={
                    this.state.completeLanguage.complete === true
                      ? this.completeSVG
                      : RadioButton20
                  }
                  href="javascript:void(0)"
                  onClick={() => {
                    this.setState({ currentStep: 3 });
                  }}>
                  Complete language environment migration
                </SideNavLink>
                <SideNavLink
                  renderIcon={
                    this.state.upgradeSoftware.complete === true
                      ? this.completeSVG
                      : RadioButton20
                  }
                  href="javascript:void(0)"
                  onClick={() => {
                    this.setState({ currentStep: 4 });
                  }}>
                  Upgrade software and hardware levels
                </SideNavLink>
                <SideNavLink
                  renderIcon={
                    this.state.installAllPrerequisite.complete === true
                      ? this.completeSVG
                      : RadioButton20
                  }
                  href="javascript:void(0)"
                  onClick={() => {
                    this.setState({ currentStep: 5 });
                  }}>
                  Install all prerequisite service updates
                </SideNavLink>
                <SideNavLink
                  renderIcon={
                    this.state.verifyRegionSize.complete === true
                      ? this.completeSVG
                      : RadioButton20
                  }
                  href="javascript:void(0)"
                  onClick={() => {
                    this.setState({ currentStep: 6 });
                  }}>
                  Verify region sizes
                </SideNavLink>
                <SideNavLink
                  renderIcon={
                    this.state.backupOldCOBOL.complete === true
                      ? this.completeSVG
                      : RadioButton20
                  }
                  href="javascript:void(0)"
                  onClick={() => {
                    this.setState({ currentStep: 7 });
                  }}>
                  Back up old COBOL compiler
                </SideNavLink>
                <SideNavLink
                  renderIcon={
                    this.state.installNewCOBOL.complete === true
                      ? this.completeSVG
                      : RadioButton20
                  }
                  href="javascript:void(0)"
                  onClick={() => {
                    this.setState({ currentStep: 8 });
                  }}>
                  Install new COBOL compiler
                </SideNavLink>
                <SideNavLink
                  renderIcon={
                    this.state.setDefaultCompiler.complete === true
                      ? this.completeSVG
                      : RadioButton20
                  }
                  href="javascript:void(0)"
                  onClick={() => {
                    this.setState({ currentStep: 9 });
                  }}>
                  Set default compiler options
                </SideNavLink>
                <SideNavLink
                  renderIcon={
                    this.state.updateCOBOLSource.complete === true
                      ? this.completeSVG
                      : RadioButton20
                  }
                  href="javascript:void(0)"
                  onClick={() => {
                    this.setState({ currentStep: 10 });
                  }}>
                  Update COBOL source code
                </SideNavLink>
                <SideNavLink
                  renderIcon={
                    this.state.regressionTesting.complete === true
                      ? this.completeSVG
                      : RadioButton20
                  }
                  href="javascript:void(0)"
                  onClick={() => {
                    this.setState({ currentStep: 11 });
                  }}>
                  Regression testing
                </SideNavLink>
                <SideNavLink
                  renderIcon={
                    this.state.verifyMigration.complete === true
                      ? this.completeSVG
                      : RadioButton20
                  }
                  href="javascript:void(0)"
                  onClick={() => {
                    this.setState({ currentStep: 12 });
                  }}>
                  Verify migration process
                </SideNavLink>
                <SideNavLink
                  renderIcon={
                    this.state.postMigration.complete === true
                      ? this.completeSVG
                      : RadioButton20
                  }
                  href="javascript:void(0)"
                  onClick={() => {
                    this.setState({ currentStep: 13 });
                  }}>
                  Post migration actions
                </SideNavLink>
              </SideNavItems>
            </SideNav>

            {content}
          </>
        </div>
      );
    }

    return (
      <Loading
        active
        className="landing_page_loading_icon"
        description="Active loading indicator"
        small={false}
        withOverlay={false}
      />
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchDataset: id => dispatch(actions.fetchDatasets(id)),
    dispatch,
  };
};

const mapStateToProps = state => {
  //console.log('current state:', state);
  //debugger;
  return {
    datasets: state.datasets.data[0],
    token: state.auth.token,
    id: state.auth.id,
    name: state.auth.name,
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AssistantPage)
);
