import React from 'react';

import {
  Form,
  FormGroup,
  Button,
  RadioButtonGroup,
  RadioButton,
} from 'carbon-components-react';

import { Content } from 'carbon-components-react/lib/components/UIShell';

export class PostMigration extends React.Component {
  proceedButton = () => {
    this.props.proceedButtonPostMigration();
  };

  goBackButton = () => {
    this.props.backButtonPostMigration();
  };

  render() {
    let version = '"Please select a version in the first step"';

    if (this.props.selectMigrationTarget.version) {
      version = this.props.selectMigrationTarget.version;
    }

    //const location = this.props.location;
    return (
      <Content id="main-content">
        <div className="bx--grid">
          <div className="bx--row">
            <section className="bx--col-lg-10">
              <h2
                style={{
                  //fontWeight: '800',
                  margin: '20px 0',
                  fontSize: '24px',
                }}>
                14. Post migration actions
              </h2>
              <p style={{ lineHeight: '20px' }}>
                Congratulations, you have successfully migrated to {version}.
              </p>

              <p style={{ lineHeight: '20px', marginTop: '20px' }}>
                Were all of your pre-COBOL 6 programs recompiled with latest COBOL
                compiler?
              </p>

              <div>
                <Form
                  className="some-class"
                  onSubmit={function noRefCheck() {}}>
                  <div style={{ marginTop: '20px' }}>
                    <FormGroup
                      className="some-class"
                      invalid={false}
                      //legendText="Radio Button heading"
                      message={false}
                      messageText="">
                      <RadioButtonGroup
                        labelPosition="right"
                        name="radio-button-group"
                        onChange={val => {
                          this.props.PostMigrationButton(val);
                        }}
                        valueSelected={this.props.currentState.yesOrNo}
                        orientation="horizontal">
                        <RadioButton
                          className="some-class"
                          id="radio-1"
                          labelText="No"
                          value="No"
                        />
                        <RadioButton
                          className="some-class"
                          id="radio-2"
                          labelText="Yes"
                          value="Yes"
                        />
                      </RadioButtonGroup>
                    </FormGroup>
                  </div>

                  <div>
                    {this.props.currentState.yesOrNo === 'No' && (
                      <div>
                        <p style={{ lineHeight: '20px' }}>
                          Note, if you deactivate/uninstall the old COBOL
                          compiler without having recompiled all of your pre-COBOL 6 
                          programs, any future changes will involve a compiler migration.
                        </p>
                      </div>
                    )}
                    {this.props.currentState.yesOrNo === 'Yes' && (
                      <div>
                        <p style={{ lineHeight: '20px' }}>
                          Uninstall the old COBOL compiler.
                        </p>
                      </div>
                    )}
                  </div>

                  <div
                    style={{
                      alignItems: 'center',
                      display: 'flex',
                      flexWrap: 'wrap',
                      marginTop: '20px',
                      float: 'right',
                      paddingBottom: '48px',
                    }}>
                    <Button
                      className="some-class"
                      disabled={false}
                      kind="secondary"
                      tabIndex={0}
                      type="submit"
                      onClick={this.goBackButton}>
                      Go back
                    </Button>

                    <Button
                      style={{ marginLeft: '10px' }}
                      className="some-class"
                      disabled={false}
                      kind="primary"
                      tabIndex={0}
                      type="submit"
                      onClick={this.proceedButton}>
                      Proceed
                    </Button>
                  </div>
                </Form>
              </div>
            </section>
          </div>
        </div>
      </Content>
    );
  }
}
