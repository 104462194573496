import React from 'react';

import {
  Form,
  FormGroup,
  Button,
  RadioButtonGroup,
  RadioButton,
} from 'carbon-components-react';

import { Content } from 'carbon-components-react/lib/components/UIShell';

export class CheckCOBOLdependencies extends React.Component {
  proceedButton = () => {
    this.props.proceedButtonCheckCOBOLdependencies();
  };

  goBackButton = () => {
    this.props.backButtonCheckCOBOLdependencies();
  };

  render() {
    //const location = this.props.location;
    return (
      <Content id="main-content">
        <div className="bx--grid">
          <div className="bx--row">
            <section className="bx--col-lg-10">
              <h2
                style={{
                  //fontWeight: '800',
                  margin: '20px 0',
                  fontSize: '24px',
                }}>
                3. Check COBOL dependencies
              </h2>
              <p style={{ lineHeight: '20px' }}>
                Use the File Manager View Load Module tool (the formerly recommended Load Module Analyzer is deprecated)
				included in IBM File Manager for z/OS to analyze whether your load libraries
                contain any OS/VS COBOL or VS COBOL II NORES programs.
              </p>

              <p style={{ lineHeight: '20px', marginTop: '20px' }}>
                Do any of your load libraries contain OS/VS COBOL or VS COBOL II
                NORES programs?
              </p>

              <div>
                <Form
                  className="some-class"
                  onSubmit={function noRefCheck() {}}>
                  <div style={{ marginTop: '20px' }}>
                    <FormGroup
                      className="some-class"
                      invalid={false}
                      //legendText="Radio Button heading"
                      message={false}
                      messageText="">
                      <RadioButtonGroup
                        labelPosition="right"
                        name="radio-button-group"
                        valueSelected={this.props.currentState.yesOrNo}
                        onChange={val => {
                          this.props.CheckCOBOLdependenciesButton(val);
                          console.log('value is:', val);
                        }}
                        orientation="horizontal">
                        <RadioButton
                          className="some-class"
                          id="radio-1"
                          labelText="No"
                          value="No"
                        />
                        <RadioButton
                          className="some-class"
                          id="radio-2"
                          labelText="Yes"
                          value="Yes"
                        />
                      </RadioButtonGroup>
                    </FormGroup>
                  </div>

                  <div>
                    {this.props.currentState.yesOrNo === 'Yes' && (
                      <div>
                        <p style={{ lineHeight: '20px' }}>
                          These OS/VS COBOL or VS COBOL II NORES programs need
                          special handling because they cannot CALL or be CALLed
                          by Enterprise COBOL 5 or 6 programs. There are 3
                          options for handling these programs.
                        </p>

                        <p style={{ lineHeight: '20px', marginTop: '20px' }}>
                          Select the option you have chosen to perform:
                        </p>

                        <FormGroup
                          className="some-class"
                          invalid={false}
                          //legendText="Radio Button heading"
                          message={false}
                          messageText="">
                          <RadioButtonGroup
                            labelPosition="right"
                            name="radio-button-group-2"
                            valueSelected={this.props.currentState.option}
                            onChange={value => {
                              this.props.CheckCOBOLdependenciesOption(value);
                            }}
                            orientation="vertical">
                            <RadioButton
                              className="some-class"
                              id="radio-3"
                              labelText="Keep the OS/VS COBOL or VS COBOL II NORES programs separate."
                              value="0"
                            />
                            <RadioButton
                              className="some-class"
                              id="radio-4"
                              labelText="Target the OS/VS COBOL or VS COBOL II NORES programs for early migration to Enterprise COBOL 5/6."
                              value="1"
                            />
                            <RadioButton
                              className="some-class"
                              id="radio-5"
                              labelText="Migrate the OS/VS COBOL or VS COBOL II NORES programs to Enterprise COBOL 4 before Enterprise COBOL
                              5/6 migration."
                              value="2"
                            />
                          </RadioButtonGroup>
                        </FormGroup>
                      </div>
                    )}
                  </div>

                  <div
                    style={{
                      alignItems: 'center',
                      display: 'flex',
                      flexWrap: 'wrap',
                      marginTop: '20px',
                      float: 'right',
                      paddingBottom: '48px',
                    }}>
                    <Button
                      className="some-class"
                      disabled={false}
                      kind="secondary"
                      tabIndex={0}
                      type="submit"
                      onClick={this.goBackButton}>
                      Go back
                    </Button>

                    <Button
                      style={{ marginLeft: '10px' }}
                      className="some-class"
                      disabled={false}
                      kind="primary"
                      tabIndex={0}
                      type="submit"
                      onClick={this.proceedButton}>
                      Proceed
                    </Button>
                  </div>
                </Form>
              </div>
            </section>
          </div>
        </div>
      </Content>
    );
  }
}
