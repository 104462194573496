import React, { Component } from 'react';
import './app.scss';
import { Content } from 'carbon-components-react/lib/components/UIShell';
import TutorialHeader from './components/TutorialHeader';
import { Route, Switch, BrowserRouter, Redirect } from 'react-router-dom';
import { Provider } from 'react-redux';
import LandingPage from './content/LandingPage';
import AssistantPage from './content/AssistantPage';
import SupportPage from './content/SupportPage';
import SummaryPage from './content/SummaryPage';
import FeedbackPage from './content/FeedbackPage';

const store = require('./reducers').init();

class App extends Component {
  render() {
    return (
      <>
        <Provider store={store}>
          <BrowserRouter>
            <TutorialHeader />
            <Content>
              <Switch>
                <Route exact path="/" component={LandingPage} />
                <Route path="/assistant" component={AssistantPage} />
                <Route path="/support" component={SupportPage} />
                <Route path="/summary" component={SummaryPage} />
                <Route path="/feedback" component={FeedbackPage} />
              </Switch>
            </Content>
          </BrowserRouter>
        </Provider>
      </>
    );
  }
}

export default App;
