import { ACCEPT_AGREEMENT, REJECT_AGREEMENT } from '../actions/types';

const INTIAL_STATE = {
  acceptUserAgreement: false,
};

export const agreementReducer = (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case ACCEPT_AGREEMENT:
      return {
        ...state,
        acceptUserAgreement: true,
      };
    case REJECT_AGREEMENT:
      return {
        ...state,
        acceptUserAgreement: false,
      };

    default:
      return state;
  }
};
