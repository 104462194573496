/*
IBM Confidential
IBM Automatic Binary Optimizer for z/OS Trial Cloud Service
Copyright IBM Corp. 2020
The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.

*/

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Button, DataTable } from 'carbon-components-react';
import { Add16 } from '@carbon/icons-react';
import * as actions from '../../actions';

const {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableHeader,
  TableToolbar,
  TableToolbarContent,
} = DataTable;

const paragraph_style = {
  marginTop: '20px',
};

class SummaryPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectMigrationTarget: {
        complete: false,
        version: '',
      },
      convertPDSLoad: { complete: false, yesOrNo: '' },
      checkCOBOLdependencies: { complete: false, yesOrNo: '', option: '' },
      completeLanguage: {
        complete: false,
        option: [false, false, false, false, false, false],
      },
      upgradeSoftware: { complete: false, yesOrNo: '' },
      installAllPrerequisite: { complete: false, yesOrNo: '' },
      verifyRegionSize: { complete: false, yesOrNo: '' },
      backupOldCOBOL: { complete: false, yesOrNo: '' },
      installNewCOBOL: { complete: false, yesOrNo: '' },
      setDefaultCompiler: { complete: false },
      updateCOBOLSource: { complete: false, yesOrNo: '' },
      regressionTesting: {
        complete: false,
        yesOrNo: '',
        yesOrNo2: false,
        modealOpen: false,
      },
      verifyMigration: { complete: false, yesOrNo: '' },
      postMigration: { complete: false, yesOrNo: '' },
      currentStep: 0,
    };
  }

  async componentDidMount() {
    await this.props.dispatch(actions.checkAuth());

    if (this.props.id) {
      console.log('I am login:', this.props.id);
      await this.props.fetchDataset(this.props.id);

      const datasets =
        typeof this.props.datasets === 'undefined' ? [] : this.props.datasets;

      //console.log('dataset is:', datasets);
      //console.log('current step:', datasets.currentStep);

      if (datasets) {
        this.setState({
          selectMigrationTarget: {
            complete: datasets.selectMigrationTarget.complete,
            version: datasets.selectMigrationTarget.version,
          },
          convertPDSLoad: {
            complete: datasets.convertPDSLoad.complete ? true : false,
            yesOrNo: datasets.convertPDSLoad.yesOrNo,
          },
          checkCOBOLdependencies: {
            complete: datasets.checkCOBOLdependencies.complete ? true : false,
            yesOrNo: datasets.checkCOBOLdependencies.yesOrNo,
            option: datasets.checkCOBOLdependencies.option,
          },
          completeLanguage: {
            complete: datasets.completeLanguage.complete ? true : false,
            option: datasets.completeLanguage.option,
          },
          upgradeSoftware: {
            complete: datasets.upgradeSoftware.complete ? true : false,
            yesOrNo: datasets.upgradeSoftware.yesOrNo,
          },
          installAllPrerequisite: {
            complete: datasets.installAllPrerequisite.complete ? true : false,
            yesOrNo: datasets.installAllPrerequisite.yesOrNo,
          },
          verifyRegionSize: {
            complete: datasets.verifyRegionSize.complete ? true : false,
            yesOrNo: datasets.verifyRegionSize.yesOrNo,
          },
          backupOldCOBOL: {
            complete: datasets.backupOldCOBOL.complete ? true : false,
            yesOrNo: datasets.backupOldCOBOL.yesOrNo,
          },
          installNewCOBOL: {
            complete: datasets.installNewCOBOL.complete ? true : false,
            yesOrNo: datasets.installNewCOBOL.yesOrNo,
          },
          setDefaultCompiler: {
            complete: datasets.setDefaultCompiler.complete ? true : false,
          },
          updateCOBOLSource: {
            complete: datasets.updateCOBOLSource.complete ? true : false,
            yesOrNo: datasets.updateCOBOLSource.yesOrNo,
          },
          regressionTesting: {
            complete: datasets.regressionTesting.comlete ? true : false,
            yesOrNo: datasets.regressionTesting.yesOrNo,
            yesOrNo2: datasets.regressionTesting.yesOrNo2,
            modealOpen: datasets.regressionTesting.modealOpen,
          },
          verifyMigration: {
            complete: datasets.verifyMigration.complete ? true : false,
            yesOrNo: datasets.verifyMigration.yesOrNo,
          },
          postMigration: {
            complete: datasets.postMigration.complete ? true : false,
            yesOrNo: datasets.postMigration.yesOrNo,
          },
          currentStep: datasets.currentStep,
        });

        //console.log('after updated current step:', this.state.currentStep);
      }
    } else {
      window.location.href = '/appid/login';
      //this.props.history.push('/');
    }
  }

  resetButton = () => {
    const datasets = {
      selectMigrationTarget: {
        complete: false,
        version: 'IBM Enterprise COBOL for z/OS 6.3',
      },
      convertPDSLoad: { complete: false, yesOrNo: '', yesOrNo2: '' },
      checkCOBOLdependencies: {
        complete: false,
        yesOrNo: '',
        option: '',
      },
      completeLanguage: {
        complete: false,
        option: [false, false, false, false, false, false],
      },
      upgradeSoftware: { complete: false, yesOrNo: '' },
      installAllPrerequisite: { complete: false, yesOrNo: '' },
      verifyRegionSize: { complete: false, yesOrNo: '' },
      backupOldCOBOL: { complete: false, yesOrNo: '' },
      installNewCOBOL: { complete: false, yesOrNo: '' },
      setDefaultCompiler: { complete: false },
      updateCOBOLSource: { complete: false, yesOrNo: '' },
      regressionTesting: {
        complete: false,
        yesOrNo: '',
        yesOrNo2: '',
        modealOpen: false,
      },
      verifyMigration: { complete: false, yesOrNo: '' },
      postMigration: { complete: false, yesOrNo: '' },
      currentStep: 0,
    };

    actions
      .updateAvailableDataset([
        this.props.id,
        datasets.selectMigrationTarget,
        datasets.convertPDSLoad,
        datasets.checkCOBOLdependencies,
        datasets.completeLanguage,
        datasets.upgradeSoftware,
        datasets.installAllPrerequisite,
        datasets.verifyRegionSize,
        datasets.backupOldCOBOL,
        datasets.installNewCOBOL,
        datasets.setDefaultCompiler,
        datasets.updateCOBOLSource,
        datasets.regressionTesting,
        datasets.verifyMigration,
        datasets.postMigration,
        datasets.currentStep,
      ])
      .then(
        data => {
          console.log('successfully update the database');
          this.props.history.push('/assistant');
        },
        err => {
          console.log('fail to update the database');
        }
      );
  };

  render() {
    //console.log('summary page dataset:', this.state.datasets);

    //console.log('convertPDSLoadStatus:', this.state.datasets.convertPDSLoad);

    let convertPDSLoadStatus = '';
    if (
      this.state.convertPDSLoad.complete &&
      this.state.convertPDSLoad.yesOrNo === 'Yes'
    ) {
      if (this.state.convertPDSLoad.yesOrNo2 === 'Yes')
        convertPDSLoadStatus = 'PDS load libraries found and migrated to PDSE';
      else convertPDSLoadStatus = 'PDS load libraries found but not migrated';
    } else if (
      this.state.convertPDSLoad.complete &&
      this.state.convertPDSLoad.yesOrNo === 'No'
    )
      convertPDSLoadStatus = 'No PDS load libraries found';

    let checkCOBOLdependenciesStatus = '';
    if (
      this.state.checkCOBOLdependencies.complete &&
      this.state.checkCOBOLdependencies.yesOrNo === 'Yes'
    ) {
      checkCOBOLdependenciesStatus = 'OS/VS COBOL programs found';
    } else if (
      this.state.checkCOBOLdependencies.complete &&
      this.state.checkCOBOLdependencies.yesOrNo === 'No'
    )
      checkCOBOLdependenciesStatus = 'No OS/VS COBOL programs found';

    let completeLanguageStatus = '';
    if (this.state.completeLanguage.complete) {
      completeLanguageStatus = 'Complete';
    }

    let upgradeSoftwareStatus = '';
    if (
      this.state.upgradeSoftware.complete &&
      this.state.upgradeSoftware.yesOrNo === 'Yes'
    )
      upgradeSoftwareStatus = 'SW/HW levels are up to date';
    else if (
      this.state.upgradeSoftware.complete &&
      this.state.upgradeSoftware.yesOrNo === 'No'
    )
      upgradeSoftwareStatus = 'SW/HW levels are not up to date';

    let installAllPrerequisiteStatus = '';
    if (this.state.installAllPrerequisite.complete)
      installAllPrerequisiteStatus = 'Complete';

    let verifyRegionSizeStatus = '';
    if (this.state.verifyRegionSize.complete)
      verifyRegionSizeStatus = 'Complete';

    let backupOldCOBOLStatus = '';
    if (
      this.state.backupOldCOBOL.complete &&
      this.state.backupOldCOBOL.yesOrNo === 'Yes'
    )
      backupOldCOBOLStatus = 'Complete';
    else if (
      this.state.backupOldCOBOL.complete &&
      this.state.backupOldCOBOL.yesOrNo === 'No'
    )
      backupOldCOBOLStatus = 'Not needed';

    let installNewCOBOLStatus = '';
    if (this.state.installNewCOBOL.complete) installNewCOBOLStatus = 'Complete';

    let setDefaultCompilerStatus = '';
    if (this.state.setDefaultCompiler.complete)
      setDefaultCompilerStatus = 'Complete';

    let updateCOBOLSourceStatus = '';
    if (
      this.state.updateCOBOLSource.complete &&
      this.state.updateCOBOLSource.yesOrNo === 'Yes'
    )
      updateCOBOLSourceStatus = 'All compiled with COBOL V3R4 and later';
    else if (
      this.state.updateCOBOLSource.complete &&
      this.state.updateCOBOLSource.yesOrNo === 'No'
    )
      updateCOBOLSourceStatus = 'Not all compiled with COBOL V3R4 and later';

    let regressionTestingStatus = '';

    if (
      this.state.regressionTesting.complete &&
      this.state.regressionTesting.yesOrNo === 'No' &&
      this.state.regressionTesting.yesOrNo2 === 'No'
    )
      regressionTestingStatus = 'Complete';
    else if (
      this.state.regressionTesting.complete &&
      (this.state.regressionTesting.yesOrNo === 'Yes' ||
        this.state.regressionTesting.yesOrNo2 === 'Yes')
    )
      regressionTestingStatus = 'Possible defect';

    let verifyMigrationStatus = '';
    if (
      this.state.verifyMigration.complete &&
      this.state.verifyMigration.yesOrNo === 'No'
    )
      verifyMigrationStatus = 'Migration successful';
    else if (
      this.state.verifyMigration.complete &&
      this.state.verifyMigration.yesOrNo === 'Yes'
    )
      verifyMigrationStatus = 'Migration not successful';

    let postMigrationStatus = '';
    if (this.state.postMigration.complete) postMigrationStatus = 'Complete';

    let rows = [
      {
        id: 0,
        stepNumber: 1,
        stepDescription: (
          <a
            href="#"
            onClick={() => {
              this.props.history.push({
                pathname: '/assistant',
                state: {
                  currentStep: 0,
                },
              });
            }}>
            Select migration target
          </a>
        ),
        status: this.state.selectMigrationTarget.version,
      },
      {
        id: 1,
        stepNumber: 2,
        stepDescription: (
          <a
            href="#"
            onClick={() => {
              this.props.history.push({
                pathname: '/assistant',
                state: {
                  currentStep: 1,
                },
              });
            }}>
            Convert PDS load libraries
          </a>
        ),
        status: convertPDSLoadStatus,
      },
      {
        id: 2,
        stepNumber: 3,
        stepDescription: (
          <a
            href="#"
            onClick={() => {
              this.props.history.push({
                pathname: '/assistant',
                state: {
                  currentStep: 2,
                },
              });
            }}>
            Check COBOL dependencies
          </a>
        ),
        status: checkCOBOLdependenciesStatus,
      },
      {
        id: 3,
        stepNumber: 4,
        stepDescription: (
          <a
            href="#"
            onClick={() => {
              this.props.history.push({
                pathname: '/assistant',
                state: {
                  currentStep: 3,
                },
              });
            }}>
            Complete language environment migration
          </a>
        ),
        status: completeLanguageStatus,
      },
      {
        id: 4,
        stepNumber: 5,
        stepDescription: (
          <a
            href="#"
            onClick={() => {
              this.props.history.push({
                pathname: '/assistant',
                state: {
                  currentStep: 4,
                },
              });
            }}>
            Upgrade software and hardware levels
          </a>
        ),
        status: upgradeSoftwareStatus,
      },
      {
        id: 5,
        stepNumber: 6,
        stepDescription: (
          <a
            href="#"
            onClick={() => {
              this.props.history.push({
                pathname: '/assistant',
                state: {
                  currentStep: 5,
                },
              });
            }}>
            Install all prerequisite service updates
          </a>
        ),
        status: installAllPrerequisiteStatus,
      },
      {
        id: 6,
        stepNumber: 7,
        stepDescription: (
          <a
            href="#"
            onClick={() => {
              this.props.history.push({
                pathname: '/assistant',
                state: {
                  currentStep: 6,
                },
              });
            }}>
            Verify region sizes
          </a>
        ),
        status: verifyRegionSizeStatus,
      },
      {
        id: 7,
        stepNumber: 8,
        stepDescription: (
          <a
            href="#"
            onClick={() => {
              this.props.history.push({
                pathname: '/assistant',
                state: {
                  currentStep: 7,
                },
              });
            }}>
            Back up old COBOL compiler
          </a>
        ),
        status: backupOldCOBOLStatus,
      },
      {
        id: 8,
        stepNumber: 9,
        stepDescription: (
          <a
            href="#"
            onClick={() => {
              this.props.history.push({
                pathname: '/assistant',
                state: {
                  currentStep: 8,
                },
              });
            }}>
            Install new COBOL compiler
          </a>
        ),
        status: installNewCOBOLStatus,
      },
      {
        id: 9,
        stepNumber: 10,
        stepDescription: (
          <a
            href="#"
            onClick={() => {
              this.props.history.push({
                pathname: '/assistant',
                state: {
                  currentStep: 9,
                },
              });
            }}>
            Set default compiler options
          </a>
        ),
        status: setDefaultCompilerStatus,
      },
      {
        id: 10,
        stepNumber: 11,
        stepDescription: (
          <a
            href="#"
            onClick={() => {
              this.props.history.push({
                pathname: '/assistant',
                state: {
                  currentStep: 10,
                },
              });
            }}>
            Update COBOL source code
          </a>
        ),
        status: updateCOBOLSourceStatus,
      },
      {
        id: 11,
        stepNumber: 12,
        stepDescription: (
          <a
            href="#"
            onClick={() => {
              this.props.history.push({
                pathname: '/assistant',
                state: {
                  currentStep: 11,
                },
              });
            }}>
            Regression testing
          </a>
        ),
        status: regressionTestingStatus,
      },
      {
        id: 12,
        stepNumber: 13,
        stepDescription: (
          <a
            href="#"
            onClick={() => {
              this.props.history.push({
                pathname: '/assistant',
                state: {
                  currentStep: 12,
                },
              });
            }}>
            Verify migration process
          </a>
        ),
        status: verifyMigrationStatus,
      },
      {
        id: 13,
        stepNumber: 14,
        stepDescription: (
          <a
            href="#"
            onClick={() => {
              this.props.history.push({
                pathname: '/assistant',
                state: {
                  currentStep: 13,
                },
              });
            }}>
            Post migration actions
          </a>
        ),
        status: postMigrationStatus,
      },
    ];

    // We would have a headers array like the following
    const headers = [
      {
        // `key` is the name of the field on the row object itself for the header
        key: 'stepNumber',
        // `header` will be the name you want rendered in the Table Header
        header: 'Step number',
      },
      {
        key: 'stepDescription',
        header: 'Step Description',
      },
      {
        key: 'status',
        header: 'Status',
      },
    ];

    return (
      <div className="bx--grid bx--grid--full-width landing-page">
        <div className="bx--row landing-page__r2">
          <div className="bx--col bx--no-gutter">
            <div className="bx--grid bx--grid--no-gutter bx--grid--full-width">
              <div className="bx--row landing-page__tab-content">
                <div className="bx--col-md-5 bx--col-lg-10">
                  <div class="bx--row">
                    <div class="bx--col">
                      <h3 className="productive-heading-03">
                        Migration summary
                      </h3>
                    </div>

                    <div class="bx--col">
                      <Button
                        onClick={this.resetButton}
                        style={{ marginLeft: '10px', float: 'right' }}>
                        Start new migration
                      </Button>
                    </div>
                  </div>

                  <div>
                    <DataTable
                      rows={rows}
                      headers={headers}
                      render={({ rows, headers, getHeaderProps }) => (
                        <TableContainer>
                          <Table>
                            <TableHead>
                              <TableRow>
                                {headers.map(header => (
                                  <TableHeader {...getHeaderProps({ header })}>
                                    {header.header}
                                  </TableHeader>
                                ))}
                              </TableRow>
                            </TableHead>

                            <TableBody>
                              {rows.map(row => (
                                <TableRow key={row.id}>
                                  {row.cells.map(cell => (
                                    <TableCell key={cell.id}>
                                      {cell.value}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchDataset: id => dispatch(actions.fetchDatasets(id)),
    dispatch,
  };
};

const mapStateToProps = state => {
  //console.log('current state:', state);
  //debugger;
  return {
    datasets: state.datasets.data[0],
    token: state.auth.token,
    id: state.auth.id,
    name: state.auth.name,
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SummaryPage)
);
